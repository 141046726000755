<template>
  <div class="wrapper-static">
    <v-card v-if="consultant != null" elevation="9" class="px-lg-5 my-3 px-2">
      <v-card-text class="position-relative d-flex flex-row align-center px-2">
        <v-avatar size="60" class="mr-4 teal">
          <v-img :src="consultant.profilePicture" @load="loaded = true"></v-img>
        </v-avatar>

        <div class="mr-3 px-lg-2">
          <v-card-title class="pt-0 px-0">
            <span class="teal--text">
              {{ consultant.firstName }}
              {{ consultant.lastName }}
            </span>
          </v-card-title>
          <v-card-subtitle class="text-xs pa-0 pt-1">
            {{ $t(consultant['headlineTranslationKey']) }}<br />
            {{ $t('PHONE') }}: {{ consultant.phoneNumber }}
          </v-card-subtitle>
        </div>
        <div class="d-flex flex-column px-1 pl-lg-5">
          <v-tooltip right open-delay="700">
            <template #activator="{ on, chatAttrs }">
              <v-btn
                fab
                dark
                color="teal"
                :disabled="!acceptedNecessary"
                class="mb-3"
                x-small
                v-bind="chatAttrs"
                @click="bookingBtnPressed"
                v-on="on"
              >
                <v-icon size="24" color="white">
                  {{ icons.mdiChat }}
                </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('buttonStartChat') }}</span>
          </v-tooltip>
          <v-tooltip right open-delay="700">
            <template #activator="{ on, videoAttrs }">
              <v-btn
                fab
                dark
                color="teal"
                x-small
                :disabled="!acceptedNecessary"
                v-bind="videoAttrs"
                @click="videoBookingModal = true"
                v-on="on"
              >
                <v-icon size="24" color="white">
                  {{ icons.mdiVideoOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('formLabelVideoCall') }}</span>
          </v-tooltip>
        </div>
      </v-card-text>
      <v-overlay :value="!loaded" class="v-overlay--absolute" primary>
        <v-progress-circular indeterminate size="34" color="teal"></v-progress-circular>
      </v-overlay>
    </v-card>
    <ModalWindow :is-open="videoBookingModal" :title="$t('formLabelVideoCall')" :maxWidth="800">
      <template #content>
        <div class="iframe-container d-flex flex-row align-items-center" style="height: 100%">
          <iframe
            v-if="videoBookingModal"
            :src="
              currentLang.startsWith('de')
                ? `${consultant.appointmentSchedulingLink}&lang=de_CH`
                : `${consultant.appointmentSchedulingLink}&lang=en_US`
            "
            style="border: 0; width: 100%"
            scrolling="yes"
          ></iframe>
        </div>
      </template>
      <template #actions>
        <v-btn outlined block color="primary" class="ml-0 mt-3" @click="videoBookingModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>
<style lang="scss">
.wrapper-static {
  position: static;

  width: fit-content;
  .bubble-card {
    .v-image__image {
      background-color: white;
    }
  }
  .v-avatar {
    cursor: default;
    border: 3px solid;
  }
}
.iframe-container {
  height: 100%;
  iframe {
    min-height: calc(100vh - 340px);
  }
}
.v-overlay--support {
  .v-overlay__scrim {
    background-color: white !important;
  }
}
</style>
<script>
import { computed } from 'vue-demi';
import { getCurrentInstance, ref, watch } from '@vue/composition-api';
import { mdiChat, mdiVideoOutline } from '@mdi/js';
import ModalWindow from '@/components/modal/ModalWindow';

export default {
  name: 'SupportBubble',
  components: { ModalWindow },
  props: {
    floating: Boolean,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const acceptedNecessary = ref(vm.$store.getters.necessaryCookies);
    const bookingBtnPressed = () => {
      vm.$userlike.userlikeStartChat();
    };
    const videoBookingModal = ref(false);
    const consultant = ref(vm.$store.getters.consultant);
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const loaded = ref(false);
    watch(
      () => vm.$store.getters.consultant,

      () => {
        consultant.value = vm.$store.getters.consultant;
      },
    );
    watch(
      () => vm.$store.getters.consultant,

      () => {
        consultant.value = vm.$store.getters.consultant;
      },
    );
    watch(
      () => vm.$store.getters.necessaryCookies,

      () => {
        if (vm.$store.getters.necessaryCookies) {
          acceptedNecessary.value = true;
        } else {
          acceptedNecessary.value = false;
        }
      },
    );
    return {
      bookingBtnPressed,
      acceptedNecessary,
      videoBookingModal,
      consultant,
      loaded,
      currentLang,
      icons: {
        mdiVideoOutline,
        mdiChat,
      },
    };
  },
};
</script>
