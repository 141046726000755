<template>
  <div class="py-sm-10 py5">
    <div class="row">
      <v-col class="mx-auto col-sm-12 col-md-10 col-lg-10 col-12">
        <v-card class="v-card--no-border" color="transparent">
          <h1 class="font-weight-bold mb-5 mt-7 text-center">{{ $t('bavInfoHeading1') }}</h1>
          <p class="mb-12 text-center">
            {{ $t('introSubFAQ') }}
          </p>
        </v-card>
        <v-card class="v-card--no-border d-flex" color="transparent">
          <v-avatar size="45" class="mb-5 primary">
            <v-icon color="white" size="30">
              {{ icons.mdiHelp }}
            </v-icon>
          </v-avatar>
          <v-card-text class="primary--text">
            <h3>{{ $t('subheadlineFAQ') }}</h3>
            <p>{{ $t('subHeadingFAQ') }}</p>
          </v-card-text>
        </v-card>
        <v-expansion-panels accordion focusable>
          <v-expansion-panel v-for="item in 17" :key="item">
            <v-expansion-panel-header class="primary--text">
              {{ $t(`faqQuestion${item}`) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p
                v-html="
                  sanitizeHtml(
                    $t(`faqAnswer${item}`, {
                      imgBenefits: require('@/assets/static/images/FAQ_DE_Benefits_and_returns.svg'),
                      imgStatutory: require('@/assets/static/images/FAQ_DE_Statutory_Pension.svg'),
                      imgMorePensionEN: require('@//assets/static/images/Degura_Graphic_More-Pension_EN.svg'),
                      imgMorePensionDE: require('@/assets/static/images/Degura_Graphic_More-Pension.svg'),
                      imgRecomContrEN: require('@/assets/static/images/Degura_Graphic_Recommended-Contribution_EN.svg'),
                      imgRecomContrDE: require('@/assets/static/images/Degura_Graphic_Recommended-Contribution.svg'),
                      imgValueDevEN: require('@/assets/static/images/Degura_Graphic_Value-Development_EN.svg'),
                      imgValueDevDE: require('@/assets/static/images/Degura_Graphic_Value-Development.svg'),
                      imgDeathDE: require('@/assets/static/images/Degura_Graphic_Death.svg'),
                      imgDeathEN: require('@/assets/static/images/Degura_Graphic_Death_EN.svg'),
                      imgValueMaxContributonDE: require('@/assets/static/images/Degura_Graphic_Max-Contribution_2023-DE.svg'),
                    }),
                    {
                      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
                      allowedAttributes: {
                        ...sanitizeHtml.defaults.allowedAttributes,
                        img: ['src', 'alt', 'title', 'loading', 'class'],
                      },
                    },
                  )
                "
                v-if="item !== 4"
              />
              <p v-else>
                {{ $t('faqAnswer4') }}

                <!-- fancy image integration deactiavte for now. Maybe we need it later. (Legacy stuff, dont worry about style)
                /<MaxContributionGraphic :currentLang="currentLang" />
                -->
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </div>
    <v-card class="v-card--no-border mb-3" color="transparent">
      <div style="margin: 5rem 0 0 0; text-align: center">
        <h2 class="font-weight-bold mb-3">{{ $t('headlineContactFAQ') }}</h2>
        <p>{{ $t('subheadlineContactFAQ') }}</p>
      </div>
      <div class="v-card--no-border d-flex flex-row align-center justify-center">
        <support-bubble-static />
      </div>
    </v-card>
  </div>
</template>
<script>
import { mdiHelpCircleOutline, mdiHelp } from '@mdi/js';
import sanitizeHtml from 'sanitize-html';
import { getCurrentInstance, computed } from '@vue/composition-api';
import SupportBubbleStatic from '@/components/support-bubble/SupportBubbleStatic.vue';
import MaxContributionGraphic from '@/components/graphics/MaxContribution.vue';

export default {
  components: { SupportBubbleStatic, MaxContributionGraphic },
  setup() {
    const vm = getCurrentInstance().proxy;
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    return {
      sanitizeHtml,
      currentLang,
      icons: {
        mdiHelpCircleOutline,
        mdiHelp,
      },
    };
  },
};
</script>
<style>
.faq-graphic {
  display: block;
  margin: 2rem auto;
  max-width: 720px;
}
</style>
