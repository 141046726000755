var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-static"},[(_vm.consultant != null)?_c('v-card',{staticClass:"px-lg-5 my-3 px-2",attrs:{"elevation":"9"}},[_c('v-card-text',{staticClass:"position-relative d-flex flex-row align-center px-2"},[_c('v-avatar',{staticClass:"mr-4 teal",attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.consultant.profilePicture},on:{"load":function($event){_vm.loaded = true}}})],1),_c('div',{staticClass:"mr-3 px-lg-2"},[_c('v-card-title',{staticClass:"pt-0 px-0"},[_c('span',{staticClass:"teal--text"},[_vm._v(" "+_vm._s(_vm.consultant.firstName)+" "+_vm._s(_vm.consultant.lastName)+" ")])]),_c('v-card-subtitle',{staticClass:"text-xs pa-0 pt-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.consultant['headlineTranslationKey']))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('PHONE'))+": "+_vm._s(_vm.consultant.phoneNumber)+" ")])],1),_c('div',{staticClass:"d-flex flex-column px-1 pl-lg-5"},[_c('v-tooltip',{attrs:{"right":"","open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var chatAttrs = ref.chatAttrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"fab":"","dark":"","color":"teal","disabled":!_vm.acceptedNecessary,"x-small":""},on:{"click":_vm.bookingBtnPressed}},'v-btn',chatAttrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChat)+" ")])],1)]}}],null,false,2702642435)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttonStartChat')))])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var videoAttrs = ref.videoAttrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","color":"teal","x-small":"","disabled":!_vm.acceptedNecessary},on:{"click":function($event){_vm.videoBookingModal = true}}},'v-btn',videoAttrs,false),on),[_c('v-icon',{attrs:{"size":"24","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiVideoOutline)+" ")])],1)]}}],null,false,2832095167)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('formLabelVideoCall')))])])],1)],1),_c('v-overlay',{staticClass:"v-overlay--absolute",attrs:{"value":!_vm.loaded,"primary":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"34","color":"teal"}})],1)],1):_vm._e(),_c('ModalWindow',{attrs:{"is-open":_vm.videoBookingModal,"title":_vm.$t('formLabelVideoCall'),"maxWidth":800},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"iframe-container d-flex flex-row align-items-center",staticStyle:{"height":"100%"}},[(_vm.videoBookingModal)?_c('iframe',{staticStyle:{"border":"0","width":"100%"},attrs:{"src":_vm.currentLang.startsWith('de')
              ? ((_vm.consultant.appointmentSchedulingLink) + "&lang=de_CH")
              : ((_vm.consultant.appointmentSchedulingLink) + "&lang=en_US"),"scrolling":"yes"}}):_vm._e()])]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"ml-0 mt-3",attrs:{"outlined":"","block":"","color":"primary"},on:{"click":function($event){_vm.videoBookingModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('buttonCancel'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }