<template>
  <div style="max-width: 50%; margin: 16px auto">
    <svg
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 648.1 863"
      style="enable-background: new 0 0 648.1 863"
      xml:space="preserve"
      v-if="currentLang.startsWith('de')"
      class="de"
    >
      <path
        class="st0"
        d="M363.3,162.2l-29.2,29.2c-1.4,1.4-3.7,1.4-5.1,0l-3.4-3.4c-1.4-1.4-1.4-3.7,0-5.1l23.2-23.3l-23.2-23.3
	c-1.4-1.4-1.4-3.7,0-5.1l3.4-3.4c1.4-1.4,3.7-1.4,5.1,0l29.2,29.2C364.7,158.5,364.7,160.8,363.3,162.2z"
      />
      <g>
        <path
          class="st1"
          d="M621.2,394.2H344.6c-6.6,0-12-5.4-12-12V284c0-6.6,5.4-12,12-12h276.6c6.6,0,12,5.4,12,12v98.2
		C633.2,388.8,627.9,394.2,621.2,394.2z"
        />
      </g>
      <g>
        <path
          class="st1"
          d="M621.9,524.6H344c-6.3,0-11.4-5.1-11.4-11.4v-99.4c0-6.3,5.1-11.4,11.4-11.4h277.8c6.3,0,11.4,5.1,11.4,11.4
		v99.4C633.2,519.5,628.1,524.6,621.9,524.6z"
        />
      </g>
      <g>
        <path
          class="st2"
          d="M301.5,847.4H28.8c-7.7,0-14-6.3-14-14V286c0-7.7,6.3-14,14-14h272.7c7.7,0,14,6.3,14,14v547.4
		C315.5,841.1,309.2,847.4,301.5,847.4z"
        />
      </g>
      <g>
        <path
          class="st3"
          d="M622.4,847.4h-279c-6,0-10.9-4.9-10.9-10.9V543.7c0-6,4.9-10.9,10.9-10.9h278.9c6,0,10.9,4.9,10.9,10.9v292.8
		C633.2,842.5,628.4,847.4,622.4,847.4z"
        />
      </g>
      <g class="st4">
        <path
          class="st5"
          d="M72.8,536.6h10.9c2.4,0,4.2,0.6,5.4,1.6c1.3,1.1,2,2.6,2,4.4c0,2.3-1.2,3.8-3.7,4.6v0.1c3,0.7,4.6,2.8,4.6,5.6
		c0,2.2-0.8,3.9-2.3,5c-1.4,1.1-3.3,1.7-5.7,1.7H72.8V536.6z M82.9,545.9c1.8,0,3-0.9,3-2.6c0-1.7-1.1-2.6-2.9-2.6h-5v5.2
		L82.9,545.9L82.9,545.9z M83.4,555.5c1.1,0,1.9-0.3,2.4-0.8s0.8-1.2,0.8-2.1c0-2-1.5-3-3.4-3H78v6h5.4V555.5z"
        />
        <path
          class="st5"
          d="M99.1,546.1L99.1,546.1c1.1-2.3,2.6-3.2,4.7-3.2c0.5,0,0.8,0,1,0.1v4.2h-0.1c-0.3-0.1-0.7-0.1-1-0.1
		c-2.6,0-4.4,1.6-4.4,4.8v7.7h-4.9v-16.5h4.7C99.1,543.1,99.1,546.1,99.1,546.1z"
        />
        <path
          class="st5"
          d="M117.9,559.7v-2.4h-0.1c-1.2,1.9-2.7,2.8-5,2.8c-3.8,0-5.8-2.4-5.8-6v-10.9h4.9v10c0,1.8,0.9,2.9,2.6,2.9
		c2,0,3.2-1.5,3.2-3.5v-9.3h4.8v16.5L117.9,559.7L117.9,559.7z"
        />
        <path
          class="st5"
          d="M124.8,543.2h2.5v-5.3h4.8v5.3h3.2v3.1H132v8.2c0,1.2,0.7,1.6,1.8,1.6c0.3,0,0.8,0,1.1-0.1h0.2v3.6
		c-0.5,0.1-1.5,0.2-2.8,0.2c-3.1,0-5.1-1-5.1-4.3v-9.2h-2.5L124.8,543.2L124.8,543.2z"
        />
        <path
          class="st5"
          d="M136.7,543.2h2.5v-5.3h4.8v5.3h3.2v3.1H144v8.2c0,1.2,0.7,1.6,1.8,1.6c0.3,0,0.8,0,1.1-0.1h0.2v3.6
		c-0.5,0.1-1.5,0.2-2.8,0.2c-3.1,0-5.1-1-5.1-4.3v-9.2h-2.5V543.2z"
        />
        <path
          class="st5"
          d="M148.9,551.4c0-5,3.5-8.7,8.7-8.7s8.7,3.8,8.7,8.7s-3.4,8.7-8.7,8.7C152.4,560.2,148.9,556.4,148.9,551.4z
		 M161.4,551.4c0-3.1-1.4-5.3-3.8-5.3c-2.5,0-3.8,2.1-3.8,5.3c0,3.1,1.3,5.2,3.8,5.2C160,556.7,161.4,554.5,161.4,551.4z"
        />
        <path
          class="st5"
          d="M168.5,560.2h4.7c0.3,1,1.2,1.7,3.1,1.7c2.3,0,3.4-1.2,3.4-3.2v-0.3c0-0.8,0-1.6,0.1-2.2h-0.1
		c-1,1.6-2.4,2.4-4.5,2.4c-3.8,0-7.2-2.8-7.2-7.8c0-4.8,2.9-8.1,6.9-8.1c2.2,0,3.8,0.7,4.7,2.6h0.1v-2.1h4.7v15
		c0,2.4-0.8,4.2-2.1,5.4c-1.4,1.2-3.5,1.8-6,1.8C171.9,565.4,169,563.5,168.5,560.2z M180,550.7c0-2.4-1.2-4.3-3.6-4.3
		c-2.1,0-3.5,1.7-3.5,4.3s1.3,4.3,3.5,4.3C178.9,555,180,553.1,180,550.7z"
        />
        <path
          class="st5"
          d="M187,551.4c0-5,3.4-8.7,8.5-8.7c2.4,0,4.2,0.8,5.7,2.1c1.9,1.8,2.7,4.6,2.7,7.8h-12c0.3,2.5,1.6,4,4,4
		c1.5,0,2.5-0.6,3-1.6h4.7c-0.8,2.9-3.5,5.1-7.8,5.1C190.3,560.2,187,556.4,187,551.4z M191.8,549.8h7c-0.2-2.3-1.5-3.7-3.4-3.7
		C193.2,546.1,192.1,547.6,191.8,549.8z"
        />
        <path
          class="st5"
          d="M211.1,545.5L211.1,545.5c1.1-1.8,2.8-2.8,5.1-2.8c3.6,0,5.7,2.4,5.7,6.1v10.9h-4.8v-9.9c0-1.8-1-3-2.8-3
		c-1.9,0-3.2,1.5-3.2,3.6v9.3h-4.9v-23.1h4.9v6.4C211.1,543.1,211.1,544.4,211.1,545.5z"
        />
        <path
          class="st5"
          d="M235,557.6L235,557.6c-1.1,1.5-2.5,2.4-5.1,2.4c-3.3,0-5.6-1.7-5.6-5c0-3.7,2.9-4.6,6.5-5
		c2.9-0.4,4.2-0.9,4.2-2.1c0-1.2-0.8-2-2.4-2c-1.9,0-2.8,0.9-2.9,2.2h-4.5c0.1-2.8,2.4-5.5,7.3-5.5c2.1,0,3.6,0.3,4.7,1
		c1.7,1,2.5,2.7,2.5,4.9v8.5c0,1.3,0.2,2,0.6,2.3v0.3h-4.7C235.3,559.3,235.1,558.6,235,557.6z M235.1,553.7v-2.1
		c-0.7,0.4-1.8,0.7-2.9,0.9c-2.1,0.5-3.2,1-3.2,2.4s1.1,2,2.4,2C233.6,556.9,235.1,555.5,235.1,553.7z"
        />
        <path class="st5" d="M243,536.6h4.9v23.1H243V536.6z" />
        <path
          class="st5"
          d="M250.2,543.2h2.5v-5.3h4.8v5.3h3.2v3.1h-3.2v8.2c0,1.2,0.7,1.6,1.8,1.6c0.3,0,0.8,0,1.1-0.1h0.2v3.6
		c-0.5,0.1-1.5,0.2-2.8,0.2c-3.1,0-5.1-1-5.1-4.3v-9.2h-2.5L250.2,543.2L250.2,543.2z"
        />
      </g>
      <g class="st4">
        <path
          class="st5"
          d="M395.1,725.4h5.4l6.1,10c1.4,2.3,3.2,6.1,3.2,6.1h0.1c0,0-0.3-4.1-0.3-6.8v-9.3h5.1v23.1h-5.4l-6-9.9
		c-1.4-2.3-3.2-6.2-3.2-6.2H400c0,0,0.3,4.2,0.3,6.8v9.3h-5.1v-23.1H395.1z"
        />
        <path
          class="st5"
          d="M417.3,740.2c0-5,3.4-8.7,8.5-8.7c2.4,0,4.2,0.8,5.7,2.1c1.9,1.8,2.7,4.6,2.7,7.8h-12c0.3,2.5,1.6,4,4,4
		c1.5,0,2.5-0.6,3-1.6h4.7c-0.8,2.9-3.5,5.1-7.8,5.1C420.7,749,417.3,745.2,417.3,740.2z M422.2,738.6h7c-0.2-2.3-1.5-3.7-3.4-3.7
		C423.6,734.9,422.5,736.4,422.2,738.6z"
        />
        <path
          class="st5"
          d="M435.3,732h2.5v-5.3h4.8v5.3h3.2v3.1h-3.2v8.2c0,1.2,0.7,1.6,1.8,1.6c0.3,0,0.8,0,1.1-0.1h0.2v3.6
		c-0.5,0.1-1.5,0.2-2.8,0.2c-3.1,0-5.1-1-5.1-4.3v-9.2h-2.5V732z"
        />
        <path
          class="st5"
          d="M447.2,732h2.5v-5.3h4.8v5.3h3.2v3.1h-3.2v8.2c0,1.2,0.7,1.6,1.8,1.6c0.3,0,0.8,0,1.1-0.1h0.2v3.6
		c-0.5,0.1-1.5,0.2-2.8,0.2c-3.1,0-5.1-1-5.1-4.3v-9.2h-2.5V732z"
        />
        <path
          class="st5"
          d="M459.5,740.2c0-5,3.5-8.7,8.7-8.7s8.7,3.8,8.7,8.7s-3.4,8.7-8.7,8.7C462.9,749,459.5,745.2,459.5,740.2z
		 M472,740.2c0-3.1-1.4-5.3-3.8-5.3c-2.5,0-3.8,2.1-3.8,5.3c0,3.1,1.3,5.2,3.8,5.2C470.6,745.4,472,743.3,472,740.2z"
        />
        <path
          class="st5"
          d="M479.1,749h4.7c0.3,1,1.2,1.7,3.1,1.7c2.3,0,3.4-1.2,3.4-3.2v-0.3c0-0.8,0-1.6,0.1-2.2h-0.1
		c-1,1.6-2.4,2.4-4.5,2.4c-3.8,0-7.2-2.8-7.2-7.8c0-4.8,2.9-8.1,6.9-8.1c2.2,0,3.8,0.7,4.7,2.6h0.1V732h4.7v15
		c0,2.4-0.8,4.2-2.1,5.4c-1.4,1.2-3.5,1.8-6,1.8C482.5,754.2,479.6,752.3,479.1,749z M490.5,739.5c0-2.4-1.2-4.3-3.6-4.3
		c-2.1,0-3.5,1.7-3.5,4.3s1.3,4.3,3.5,4.3C489.5,743.8,490.5,741.9,490.5,739.5z"
        />
        <path
          class="st5"
          d="M497.6,740.2c0-5,3.4-8.7,8.5-8.7c2.4,0,4.2,0.8,5.7,2.1c1.9,1.8,2.7,4.6,2.7,7.8h-12c0.3,2.5,1.6,4,4,4
		c1.5,0,2.5-0.6,3-1.6h4.7c-0.8,2.9-3.5,5.1-7.8,5.1C500.9,749,497.6,745.2,497.6,740.2z M502.4,738.6h7c-0.2-2.3-1.5-3.7-3.4-3.7
		C503.8,734.9,502.7,736.4,502.4,738.6z"
        />
        <path
          class="st5"
          d="M521.7,734.3L521.7,734.3c1.1-1.8,2.8-2.8,5.1-2.8c3.6,0,5.7,2.4,5.7,6.1v10.9h-4.8v-9.9c0-1.8-1-3-2.8-3
		c-1.9,0-3.2,1.5-3.2,3.6v9.3h-4.9v-23.1h4.9v6.4C521.7,731.9,521.7,733.2,521.7,734.3z"
        />
        <path
          class="st5"
          d="M545.6,746.4L545.6,746.4c-1.1,1.5-2.5,2.4-5.1,2.4c-3.3,0-5.6-1.7-5.6-5c0-3.7,2.9-4.6,6.5-5
		c2.9-0.4,4.2-0.9,4.2-2.1c0-1.2-0.8-2-2.4-2c-1.9,0-2.8,0.9-2.9,2.2h-4.5c0.1-2.8,2.4-5.5,7.3-5.5c2.1,0,3.6,0.3,4.7,1
		c1.7,1,2.5,2.7,2.5,4.9v8.5c0,1.3,0.2,2,0.6,2.3v0.3h-4.7C545.9,748.1,545.7,747.4,545.6,746.4z M545.6,742.5v-2.1
		c-0.7,0.4-1.8,0.7-2.9,0.9c-2.1,0.5-3.2,1-3.2,2.4s1.1,2,2.4,2C544.2,745.7,545.6,744.3,545.6,742.5z"
        />
        <path class="st5" d="M553.6,725.4h4.9v23.1h-4.9V725.4z" />
        <path
          class="st5"
          d="M560.8,732h2.5v-5.3h4.8v5.3h3.2v3.1H568v8.2c0,1.2,0.7,1.6,1.8,1.6c0.3,0,0.8,0,1.1-0.1h0.2v3.6
		c-0.5,0.1-1.5,0.2-2.8,0.2c-3.1,0-5.1-1-5.1-4.3v-9.2h-2.5L560.8,732L560.8,732z"
        />
      </g>
      <g class="st4">
        <path
          class="st6"
          d="M444.1,465.4h4.1c0.2,1.9,1.5,2.8,3.8,2.8c1.6,0,3.1-0.6,3.1-2.1c0-1.6-1.5-1.9-4.1-2.5
		c-3.5-0.8-6.3-1.7-6.3-5.4c0-3.7,3-5.4,7-5.4s6.7,1.8,7,5.3h-4c-0.2-1.4-1.3-2.2-3.1-2.2c-1.7,0-2.8,0.7-2.8,1.8
		c0,1.4,1.5,1.7,4.3,2.3c3.2,0.7,6.2,1.8,6.2,5.5s-3.1,5.8-7.2,5.8C447.3,471.5,444.3,469.3,444.1,465.4z"
        />
        <path
          class="st6"
          d="M460.4,458.3h1.9v-4.1h3.7v4.1h2.5v2.4H466v6.3c0,1,0.5,1.2,1.4,1.2c0.2,0,0.6,0,0.9,0h0.2v2.8
		c-0.4,0.1-1.2,0.2-2.2,0.2c-2.4,0-4-0.8-4-3.4v-7.2h-1.9V458.3z"
        />
        <path
          class="st6"
          d="M469.9,464.7c0-3.8,2.7-6.8,6.6-6.8c1.8,0,3.3,0.6,4.4,1.6c1.4,1.4,2.1,3.6,2.1,6.1h-9.3
		c0.2,1.9,1.3,3.1,3.1,3.1c1.2,0,1.9-0.5,2.3-1.2h3.6c-0.6,2.3-2.7,4-6,4C472.4,471.4,469.9,468.5,469.9,464.7z M473.6,463.4h5.4
		c-0.1-1.8-1.1-2.9-2.6-2.9C474.7,460.6,473.8,461.7,473.6,463.4z"
        />
        <path
          class="st6"
          d="M493.1,471.1v-1.8H493c-0.9,1.5-2.1,2.2-3.8,2.2c-2.9,0-4.5-1.9-4.5-4.7v-8.4h3.8v7.7c0,1.4,0.7,2.2,2,2.2
		c1.5,0,2.4-1.2,2.4-2.7v-7.2h3.7v12.8h-3.5V471.1z"
        />
        <path
          class="st6"
          d="M498.5,464.7c0-3.8,2.7-6.8,6.6-6.8c1.8,0,3.3,0.6,4.4,1.6c1.4,1.4,2.1,3.6,2.1,6.1h-9.3
		c0.2,1.9,1.3,3.1,3.1,3.1c1.2,0,1.9-0.5,2.3-1.2h3.6c-0.6,2.3-2.7,4-6,4C501.1,471.4,498.5,468.5,498.5,464.7z M502.3,463.4h5.4
		c-0.1-1.8-1.1-2.9-2.6-2.9C503.4,460.6,502.5,461.7,502.3,463.4z"
        />
        <path
          class="st6"
          d="M517.1,460.6L517.1,460.6c0.9-1.8,2-2.5,3.6-2.5c0.4,0,0.6,0,0.8,0.1v3.3h-0.1c-0.3,0-0.5-0.1-0.8-0.1
		c-2,0-3.4,1.2-3.4,3.7v5.9h-3.8v-12.8h3.6v2.4H517.1z"
        />
      </g>
      <g class="st4">
        <path
          class="st6"
          d="M370.8,333h4.1c0.2,1.9,1.5,2.8,3.8,2.8c1.6,0,3.1-0.6,3.1-2.1c0-1.6-1.5-1.9-4.1-2.5
		c-3.5-0.8-6.3-1.7-6.3-5.4c0-3.7,3-5.4,7-5.4s6.7,1.8,7,5.3h-4c-0.2-1.4-1.3-2.2-3.1-2.2c-1.7,0-2.8,0.7-2.8,1.8
		c0,1.4,1.5,1.7,4.3,2.3c3.2,0.7,6.2,1.8,6.2,5.5s-3.1,5.8-7.2,5.8C374,339.1,371,336.9,370.8,333z"
        />
        <path
          class="st6"
          d="M387.6,332.3c0-3.8,2.7-6.8,6.8-6.8c4.1,0,6.7,2.9,6.7,6.8c0,3.8-2.7,6.8-6.7,6.8
		C390.3,339.1,387.6,336.1,387.6,332.3z M397.2,332.3c0-2.4-1.1-4.1-2.9-4.1c-1.9,0-2.9,1.7-2.9,4.1s1,4,2.9,4
		S397.2,334.7,397.2,332.3z"
        />
        <path
          class="st6"
          d="M402.3,335.8l4.6-5.2c0.8-0.8,2.1-2,2.1-2v-0.1c0,0-1.7,0.2-2.8,0.2h-3.4v-2.9h10.4v2.7l-4.4,5
		c-0.9,0.9-2.4,2.2-2.4,2.2v0.1c0,0,1.8-0.2,3-0.2h4.2v3h-11.3L402.3,335.8L402.3,335.8z"
        />
        <path class="st6" d="M415.6,320.8h3.8v3.3h-3.8V320.8z M415.6,325.9h3.8v12.8h-3.8V325.9z" />
        <path
          class="st6"
          d="M429.6,337.1L429.6,337.1c-0.9,1.2-1.9,1.9-3.9,1.9c-2.5,0-4.4-1.3-4.4-3.9c0-2.8,2.2-3.6,5-3.9
		c2.3-0.3,3.2-0.7,3.2-1.6s-0.6-1.5-1.9-1.5c-1.4,0-2.1,0.7-2.2,1.7H422c0.1-2.2,1.9-4.2,5.7-4.2c1.6,0,2.8,0.2,3.7,0.8
		c1.3,0.8,1.9,2.1,1.9,3.8v6.6c0,1,0.1,1.6,0.5,1.8v0.2h-3.7C429.9,338.4,429.7,337.8,429.6,337.1z M429.7,334.1v-1.7
		c-0.6,0.3-1.4,0.5-2.2,0.7c-1.7,0.4-2.4,0.8-2.4,1.9s0.8,1.5,1.9,1.5C428.6,336.5,429.7,335.5,429.7,334.1z"
        />
        <path class="st6" d="M435.9,320.8h3.8v17.9h-3.8V320.8z" />
        <path
          class="st6"
          d="M441,325.9h3.9l1.6,5.1c0.5,1.7,1,4.5,1,4.5h0.1c0,0,0.5-2.8,1-4.5l1.6-5.1h3.8l-4.5,12.8h-4.1L441,325.9z"
        />
        <path
          class="st6"
          d="M454.3,332.3c0-3.8,2.7-6.8,6.6-6.8c1.8,0,3.3,0.6,4.4,1.6c1.4,1.4,2.1,3.6,2.1,6.1H458
		c0.2,1.9,1.3,3.1,3.1,3.1c1.2,0,1.9-0.5,2.3-1.2h3.6c-0.6,2.3-2.7,4-6,4C456.8,339,454.3,336.1,454.3,332.3z M458,331h5.4
		c-0.1-1.8-1.1-2.9-2.6-2.9C459.1,328.2,458.2,329.3,458,331z"
        />
        <path
          class="st6"
          d="M472.8,328.2L472.8,328.2c0.9-1.8,2-2.5,3.6-2.5c0.4,0,0.6,0,0.8,0.1v3.3h-0.1c-0.3,0-0.5-0.1-0.8-0.1
		c-2,0-3.4,1.2-3.4,3.7v5.9h-3.8v-12.8h3.6L472.8,328.2L472.8,328.2z"
        />
        <path
          class="st6"
          d="M478.2,334.7h3.6c0.2,1.2,1.1,1.9,2.6,1.9c1.3,0,2.1-0.6,2.1-1.5c0-1.2-1.5-1.3-3.3-1.6c-2.3-0.4-4.6-1-4.6-4
		c0-2.6,2.4-4,5.4-4c3.6,0,5.3,1.5,5.7,3.8h-3.5c-0.2-0.9-1-1.4-2.2-1.4s-1.9,0.5-1.9,1.3c0,1,1.4,1.1,3.1,1.4
		c2.3,0.4,4.9,1,4.9,4.2c0,2.8-2.4,4.2-5.8,4.2C480.6,339.1,478.4,337.3,478.2,334.7z"
        />
        <path class="st6" d="M491.9,320.8h3.8v3.3h-3.8V320.8z M491.9,325.9h3.8v12.8h-3.8V325.9z" />
        <path
          class="st6"
          d="M497.6,332.3c0-3.8,2.6-6.8,6.6-6.8c3.4,0,5.5,1.9,6,4.7h-3.6c-0.3-1.2-1-1.9-2.3-1.9c-1.9,0-2.9,1.6-2.9,4
		c0,2.3,1,3.9,2.9,3.9c1.4,0,2.2-0.8,2.4-2.3h3.6c-0.1,2.9-2.4,5.2-6,5.2C500.2,339.1,497.6,336.1,497.6,332.3z"
        />
        <path
          class="st6"
          d="M515.8,327.7L515.8,327.7c0.8-1.4,2.1-2.2,3.9-2.2c2.8,0,4.4,1.9,4.4,4.7v8.4h-3.7V331c0-1.4-0.7-2.3-2.1-2.3
		c-1.5,0-2.4,1.2-2.4,2.8v7.2h-3.8v-17.9h3.8v4.9C515.8,325.8,515.8,326.9,515.8,327.7z"
        />
        <path
          class="st6"
          d="M525.9,332.3c0-3.8,2.7-6.8,6.6-6.8c1.8,0,3.3,0.6,4.4,1.6c1.4,1.4,2.1,3.6,2.1,6.1h-9.3
		c0.2,1.9,1.3,3.1,3.1,3.1c1.2,0,1.9-0.5,2.3-1.2h3.6c-0.6,2.3-2.7,4-6,4C528.5,339,525.9,336.1,525.9,332.3z M529.7,331h5.4
		c-0.1-1.8-1.1-2.9-2.6-2.9C530.8,328.2,529.9,329.3,529.7,331z"
        />
        <path
          class="st6"
          d="M544.5,328.2L544.5,328.2c0.9-1.8,2-2.5,3.6-2.5c0.4,0,0.6,0,0.8,0.1v3.3h-0.1c-0.3,0-0.5-0.1-0.8-0.1
		c-2,0-3.4,1.2-3.4,3.7v5.9h-3.8v-12.8h3.6v2.4H544.5z"
        />
        <path
          class="st6"
          d="M559,338.7v-1.8h-0.1c-0.9,1.5-2.1,2.2-3.8,2.2c-2.9,0-4.5-1.9-4.5-4.7V326h3.8v7.7c0,1.4,0.7,2.2,2,2.2
		c1.5,0,2.4-1.2,2.4-2.7V326h3.7v12.8H559V338.7z"
        />
        <path
          class="st6"
          d="M568.7,327.8L568.7,327.8c0.9-1.5,2.2-2.3,4-2.3c2.8,0,4.4,1.9,4.4,4.7v8.4h-3.7V331c0-1.4-0.7-2.3-2.1-2.3
		c-1.5,0-2.4,1.2-2.4,2.8v7.2h-3.8v-12.8h3.6V327.8z"
        />
        <path
          class="st6"
          d="M579.3,339.1h3.7c0.2,0.8,1,1.3,2.4,1.3c1.8,0,2.7-0.9,2.7-2.5v-0.3c0-0.6,0-1.2,0.1-1.7h-0.1
		c-0.7,1.2-1.9,1.9-3.5,1.9c-3,0-5.6-2.2-5.6-6c0-3.7,2.2-6.3,5.4-6.3c1.7,0,3,0.6,3.7,2l0,0v-1.6h3.6v11.6c0,1.9-0.6,3.3-1.7,4.2
		c-1.1,1-2.7,1.4-4.6,1.4C582,343.1,579.7,341.6,579.3,339.1z M588.2,331.7c0-1.9-0.9-3.3-2.8-3.3c-1.7,0-2.7,1.3-2.7,3.3
		s1,3.3,2.7,3.3C587.4,335,588.2,333.6,588.2,331.7z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M303.5,307.8H26.8c-6.6,0-12-5.4-12-12V176.7c0-6.6,5.4-12,12-12h276.6c6.6,0,12,5.4,12,12v119.2
		C315.5,302.5,310.1,307.8,303.5,307.8z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M303.5,156.1H26.8c-6.6,0-12-5.4-12-12V24.9c0-6.6,5.4-12,12-12h276.6c6.6,0,12,5.4,12,12v119.2
		C315.5,150.7,310.1,156.1,303.5,156.1z"
        />
      </g>
      <g class="st4">
        <path
          class="st7"
          d="M445.4,198.2L445.4,198.2l-0.1,2.3h-2.8v-22.3h2.8v5.7c0,1.2-0.1,2.4-0.1,3.1h0.1c1-1.7,2.8-2.9,5.2-2.9
		c4.1,0,6.9,3.2,6.9,8.5c0,5.2-2.6,8.5-7,8.5C448.2,201,446.4,200,445.4,198.2z M454.5,192.5c0-3.5-1.4-6.2-4.4-6.2
		c-3.4,0-5,2.8-5,6.2c0,3.5,1.7,6.1,5,6.1C453.1,198.6,454.5,196,454.5,192.5z"
        />
        <path class="st7" d="M460.3,178.2h2.9v3.2h-2.9V178.2z M460.3,184.4h2.8v16.1h-2.8V184.4z" />
        <path
          class="st7"
          d="M465.9,195.6h2.8c0.3,2.3,2.1,3.1,4.4,3.1c2.5,0,3.6-1.1,3.6-2.6c0-1.8-1.4-2.2-4.2-2.8c-3.1-0.7-6-1.3-6-4.9
		c0-2.8,2.2-4.5,5.9-4.5c4,0,6.1,1.9,6.4,4.7H476c-0.2-1.7-1.4-2.6-3.7-2.6c-2.2,0-3.1,1-3.1,2.2c0,1.7,1.6,2,4.3,2.5
		c3.2,0.6,6,1.3,6,5.1c0,3.3-2.7,5-6.5,5C468.7,201,466.2,198.9,465.9,195.6z"
        />
        <path
          class="st7"
          d="M490,198.3l7.2-9.4c0.7-0.8,1.9-2.2,1.9-2.2v-0.1c0,0-1.9,0.1-3,0.1h-5.5v-2.4h12.1v1.7l-7.3,9.6
		c-0.7,0.9-2,2.3-2,2.3v0.1c0,0,2-0.1,3.2-0.1h6.5v2.5h-13v-2.1H490z"
        />
        <path
          class="st7"
          d="M516.2,200.5v-2.2h-0.1c-1.1,1.6-2.5,2.6-4.9,2.6c-3.3,0-5.5-2-5.5-5.4v-11.1h2.8v10.9c0,2,1.3,3.2,3.5,3.2
		c2.5,0,4-1.9,4-4.4v-9.8h2.8v16.1L516.2,200.5L516.2,200.5z"
        />
      </g>
      <g class="st4">
        <path
          class="st7"
          d="M385.6,230.4h4.8c0.3,1.6,1.7,2.6,3.7,2.6c2.2,0,3.8-1.5,3.8-3.8s-1.6-3.7-3.7-3.7c-1.5,0-2.7,0.6-3.3,1.4
		h-4.6l2.2-11.9h13v3.9H392l-1,4.7h0.1c1-1.1,2.3-1.7,4.4-1.7c3.9,0,7.3,2.8,7.3,7.3c0,4.6-3.5,7.6-8.5,7.6
		C389.5,237,386,234.5,385.6,230.4z"
        />
        <path
          class="st7"
          d="M404.8,230.4c0-2.5,1.5-4.5,4.2-5.4v-0.1c-2.3-0.8-3.3-2.4-3.3-4.3c0-3.5,3.1-5.9,7.9-5.9s7.9,2.2,7.9,5.8
		c0,1.9-1.1,3.5-3.3,4.3v0.1c2.8,0.8,4.3,2.8,4.3,5.4c0,4.3-3.7,6.7-8.8,6.7C408.5,237,404.8,234.5,404.8,230.4z M417.5,230.1
		c0-2.1-1.4-3.4-3.8-3.4s-3.9,1.3-3.9,3.4c0,2,1.5,3.2,3.9,3.2C415.9,233.3,417.5,232,417.5,230.1z M416.8,220.8
		c0-1.7-1.2-2.8-3.3-2.8c-2.1,0-3.3,1.1-3.3,2.8c0,1.6,1.2,2.7,3.3,2.7C415.7,223.5,416.8,222.4,416.8,220.8z"
        />
        <path
          class="st7"
          d="M435.2,231.6h-10v-4.3l10-12.4h4.7V228h3.2v3.6h-3.2v4.8h-4.7V231.6z M431.5,228h3.7v-5.2
		c0-1.3,0.2-3.3,0.2-3.3h-0.1c0,0-1.2,1.9-2.1,3l-2.3,2.9c-0.9,1.1-2.5,2.7-2.5,2.7v0.1C428.5,228.2,430.3,228,431.5,228z"
        />
        <path class="st7" d="M444.6,239.8c1.4-0.4,2.3-1.5,2.3-3.3v-0.1h-2.3v-4.9h5v4.6c0,3.8-2.2,5.5-5,5.7V239.8z" />
        <path
          class="st7"
          d="M451.5,225.8c0-6.8,3.8-11.2,9.3-11.2c5.6,0,9.3,4.3,9.3,11.2c0,6.8-3.7,11.2-9.3,11.2
		S451.5,232.6,451.5,225.8z M465,225.8c0-4.4-1.2-7.3-4.3-7.3c-3,0-4.3,3-4.3,7.3s1.3,7.3,4.3,7.3C463.8,233.1,465,230.2,465,225.8z
		"
        />
        <path
          class="st7"
          d="M472.2,225.8c0-6.8,3.8-11.2,9.3-11.2c5.6,0,9.3,4.3,9.3,11.2c0,6.8-3.7,11.2-9.3,11.2
		C476,237,472.2,232.6,472.2,225.8z M485.8,225.8c0-4.4-1.2-7.3-4.3-7.3c-3,0-4.3,3-4.3,7.3s1.3,7.3,4.3,7.3
		C484.6,233.1,485.8,230.2,485.8,225.8z"
        />
        <path
          class="st7"
          d="M499.9,222.9h1.6c1-4.9,4.4-8.2,9.1-8.2c5,0,7.8,3.1,8.2,7.4H514c-0.3-2.2-1.4-3.6-3.5-3.6
		c-2.2,0-3.5,1.7-4,4.4h5v2h-5.2c0,0.3,0,0.7,0,1c0,0.4,0,0.8,0,1.2h5.2v1.9h-5c0.6,2.6,2,4,4.2,4s3.4-1.7,3.6-4h4.7
		c-0.2,4.6-3.1,8-8.3,8c-4.7,0-8.2-3.1-9.2-8h-1.6v-2h1.3c0-0.4,0-0.8,0-1.2c0-0.3,0-0.7,0-1h-1.3V222.9z"
        />
      </g>
      <g class="st4">
        <path
          class="st7"
          d="M529.1,220.4h2.8v2.4h0.1c1-1.7,2.7-2.8,4.8-2.8c2,0,3.5,1,4.2,3h0.1c1.1-2,3-3,5-3c2.9,0,5,1.9,5,5.2v11.2
		h-2.8v-11c0-2-1.3-3.1-2.9-3.1c-2,0-3.8,1.8-3.8,4.2v9.9h-2.8v-11c0-2-1.1-3.1-2.8-3.1c-1.9,0-4,1.8-4,4.2v9.9h-2.8L529.1,220.4
		L529.1,220.4z"
        />
        <path
          class="st7"
          d="M553.4,220.4h2.5v-5.1h2.8v5.1h3.3v2.2h-3.3v10.2c0,1.2,0.6,1.5,1.6,1.5c0.5,0,1.1-0.1,1.4-0.2h0.1v2.3
		c-0.8,0.2-1.5,0.3-2.2,0.3c-2.2,0-3.7-0.9-3.7-3.3v-10.8h-2.5L553.4,220.4L553.4,220.4z"
        />
        <path class="st7" d="M565,214.2h2.8v22.3H565V214.2z" />
        <path class="st7" d="M571.4,233.2h3.2v3.3h-3.2V233.2z" />
      </g>
      <g>
        <path
          class="st6"
          d="M493.9,379.1c1.4-2.3,3-4.6,4.7-7c0.7-1,0.7-2,0.2-2.9c-0.5-1.1-1.4-1.6-2.5-1.7c-0.1,0-0.2,0-0.3,0
		c-8.6,0-17.3,0-25.9,0c-1.7,0-2.9,1.1-3.2,2.7c-0.1,0.7,0.1,1.3,0.4,1.9c1.6,2.3,3.1,4.5,4.7,6.9h21.9L493.9,379.1z"
        />
        <path
          class="st6"
          d="M515,415.9c-0.2-3.8-1.2-7.5-2.6-11.1c-1.7-4.4-4.3-8.3-7.4-11.8s-6.7-6.5-10.5-9.2h-23.2
		c-2.9,2-5.7,4.1-8.2,6.7c-5,5-8.8,10.7-10.9,17.6c-0.8,2.5-1.3,5-1.5,7.6c0,0.7-0.1,1.3-0.1,2c0,0.1,0,0.2,0,0.3
		c0,0.5,0.1,1,0.1,1.5c0,2.6,0.9,5,2.4,7.1c2.3,3.1,5.5,4.7,9.2,5.3c0.7,0.1,1.3,0.1,2.1,0.1c12.5,0,25.2,0,37.7,0
		c5.3-0.3,9.5-2.7,12-7.5c0.8-1.4,1.1-2.9,1.2-4.6c0-0.1,0-0.3,0-0.4c0-0.5,0-1.2,0-1.7C515.1,417.1,515.1,416.6,515,415.9z"
        />
        <polygon class="st8" points="493.9,379.1 493.9,378.9 472,378.9 471.8,378.9 471.3,383.9 494.5,383.9 	" />
        <path
          class="st8"
          d="M491.1,416.8c-0.1-0.4-0.5-0.7-0.9-0.7c-0.7,0.1-1.7,0.3-2.9,0.3c-3.6,0-6.5-2.3-7.9-5.5h7.3
		c0.3,0,0.7-0.2,0.8-0.7l0.4-1.9c0.1-0.5-0.2-1-0.8-1h-8.6c-0.1-0.9-0.1-1.9,0-2.7h9.4c0.3,0,0.7-0.2,0.8-0.7l0.4-1.9
		c0.1-0.5-0.2-1-0.8-1h-8.8c1.3-2.8,4-4.8,7.5-4.8c0.9,0,1.9,0.1,2.4,0.2c0.4,0.1,0.8-0.1,0.9-0.5l0.8-2.8c0.1-0.4-0.1-0.9-0.5-1
		c-0.8-0.1-2.1-0.3-3.4-0.3c-6.2,0-11.2,3.8-13.1,9.3H472c-0.4,0-0.8,0.3-0.8,0.8v1.9c0,0.4,0.3,0.8,0.8,0.8h1.4
		c-0.1,0.9-0.1,1.9,0,2.7H472c-0.4,0-0.8,0.3-0.8,0.8v1.9c0,0.4,0.3,0.8,0.8,0.8h2c1.6,5.8,6.7,10,13.3,10c1.6,0,3.2-0.3,3.9-0.5
		c0.4-0.1,0.7-0.5,0.5-0.9L491.1,416.8z"
        />
      </g>
      <g>
        <path
          class="st5"
          d="M622.3,649.6H343.5c-6,0-10.9-4.9-10.9-10.9v-94.9c0-6,4.9-10.9,10.9-10.9h278.8c6,0,10.9,4.9,10.9,10.9v94.9
		C633.2,644.7,628.4,649.6,622.3,649.6z"
        />
      </g>
      <g class="st4">
        <path
          class="st2"
          d="M450.9,569h7.4c1.7,0,3,0.4,3.9,1.3c0.8,0.7,1.3,1.7,1.3,3c0,1.7-0.9,2.8-2.7,3.3v0.1c2,0.5,3.3,1.9,3.3,4
		c0,1.5-0.5,2.6-1.5,3.4c-0.9,0.9-2.3,1.4-4.1,1.4h-7.7V569H450.9z M457.9,575.7c1.6,0,2.6-0.8,2.6-2.2c0-1.3-0.9-2.1-2.4-2.1H454
		v4.3H457.9z M458.3,582.9c0.9,0,1.6-0.3,2.1-0.7c0.4-0.4,0.7-1,0.7-1.7c0-1.7-1.2-2.5-2.8-2.5H454v4.9H458.3z"
        />
        <path class="st2" d="M466.1,569h2.9v2.7h-2.9V569z M466.1,573.6h2.8v11.8h-2.8V573.6z" />
        <path
          class="st2"
          d="M470.7,581.7h2.7c0.2,1.4,1.3,2,2.8,2s2.3-0.7,2.3-1.6c0-1.2-1.2-1.4-3-1.7c-2.2-0.4-4.3-0.9-4.3-3.6
		c0-2.2,1.9-3.5,4.6-3.5c3.1,0,4.7,1.4,5,3.5H478c-0.2-1-0.9-1.6-2.3-1.6c-1.3,0-2,0.6-2,1.4c0,1,1.2,1.2,3,1.6
		c2.2,0.4,4.4,0.9,4.4,3.8c0,2.5-2.1,3.8-5,3.8C472.8,585.7,470.9,584.1,470.7,581.7z"
        />
        <path
          class="st2"
          d="M485.9,575.3L485.9,575.3c0.9-1.3,2-2,3.7-2c2.5,0,4,1.6,4,4.1v8h-2.8v-7.5c0-1.4-0.8-2.2-2.2-2.2
		c-1.6,0-2.7,1.2-2.7,2.9v6.9h-2.8V569h2.8v4.4C485.9,573.7,485.9,574.6,485.9,575.3z"
        />
        <path
          class="st2"
          d="M495.5,579.5c0-3.5,2.4-6.2,5.8-6.2c1.7,0,3.1,0.6,4,1.7c1.1,1.2,1.7,3.1,1.7,5.2h-8.8c0.2,2,1.3,3.4,3.2,3.4
		c1.3,0,2.2-0.6,2.6-1.5h2.7c-0.6,2.1-2.5,3.7-5.3,3.7C497.7,585.7,495.5,583,495.5,579.5z M498.3,578.4h5.9
		c-0.1-1.9-1.1-3.1-2.8-3.1C499.5,575.3,498.5,576.6,498.3,578.4z"
        />
        <path
          class="st2"
          d="M511.7,575.7L511.7,575.7c0.8-1.5,1.9-2.3,3.4-2.3c0.3,0,0.5,0,0.7,0.1v2.5h-0.1c-0.3-0.1-0.5-0.1-0.8-0.1
		c-1.8,0-3.2,1.2-3.2,3.5v5.9H509v-11.8h2.7V575.7z"
        />
      </g>
      <g class="st4">
        <path
          class="st2"
          d="M408.9,595.4h3.9l5.9,16.4h-4l-1-3.1h-5.9l-1,3.1H403L408.9,595.4z M408.7,605.9h4.2l-1-3.1
		c-0.7-2.2-1.1-4.4-1.1-4.4l0,0c0,0-0.6,2.8-1.1,4.4L408.7,605.9z"
        />
        <path class="st2" d="M420.2,595.4h3.5v16.4h-3.5V595.4z" />
        <path
          class="st2"
          d="M425.3,600h1.8v-3.7h3.4v3.7h2.3v2.2h-2.3v5.8c0,0.9,0.5,1.1,1.3,1.1c0.2,0,0.6,0,0.8,0h0.2v2.6
		c-0.4,0.1-1.1,0.2-2,0.2c-2.2,0-3.7-0.7-3.7-3.1v-6.6h-1.8L425.3,600L425.3,600z"
        />
        <path
          class="st2"
          d="M434,605.9c0-3.5,2.4-6.2,6-6.2c1.7,0,3,0.6,4,1.5c1.3,1.3,1.9,3.3,1.9,5.6h-8.5c0.2,1.8,1.2,2.8,2.8,2.8
		c1.1,0,1.8-0.4,2.1-1.1h3.3c-0.6,2.1-2.5,3.7-5.5,3.7C436.3,612.1,434,609.4,434,605.9z M437.4,604.8h5c-0.1-1.6-1-2.6-2.4-2.6
		C438.4,602.2,437.6,603.2,437.4,604.8z"
        />
        <path
          class="st2"
          d="M451,602.1L451,602.1c0.8-1.6,1.9-2.3,3.3-2.3c0.3,0,0.6,0,0.7,0.1v3h-0.1c-0.2,0-0.5-0.1-0.7-0.1
		c-1.9,0-3.1,1.1-3.1,3.4v5.4h-3.5V600h3.3v2.1H451z"
        />
        <path
          class="st2"
          d="M455.9,608.1h3.3c0.2,1.1,1,1.7,2.4,1.7c1.2,0,1.9-0.5,1.9-1.3c0-1.1-1.4-1.2-3-1.5c-2.1-0.4-4.2-0.9-4.2-3.7
		c0-2.4,2.2-3.7,4.9-3.7c3.3,0,4.9,1.4,5.2,3.5h-3.2c-0.2-0.8-0.9-1.3-2-1.3s-1.7,0.4-1.7,1.2c0,0.9,1.3,1,2.9,1.3
		c2.1,0.4,4.5,0.9,4.5,3.9c0,2.5-2.2,3.9-5.3,3.9C458.1,612.1,456.1,610.5,455.9,608.1z"
        />
        <path
          class="st2"
          d="M467.2,600h3.6l1.4,4.7c0.5,1.6,0.9,4.1,0.9,4.1h0.1c0,0,0.4-2.5,0.9-4.1l1.5-4.7h3.5l-4.1,11.7h-3.7
		L467.2,600z"
        />
        <path
          class="st2"
          d="M479.3,605.9c0-3.5,2.5-6.2,6.2-6.2s6.2,2.7,6.2,6.2s-2.4,6.2-6.2,6.2C481.7,612.1,479.3,609.4,479.3,605.9z
		 M488.1,605.9c0-2.2-1-3.7-2.7-3.7c-1.8,0-2.7,1.5-2.7,3.7s0.9,3.7,2.7,3.7C487.2,609.6,488.1,608.1,488.1,605.9z"
        />
        <path
          class="st2"
          d="M496.7,602.1L496.7,602.1c0.8-1.6,1.9-2.3,3.3-2.3c0.3,0,0.6,0,0.7,0.1v3h-0.1c-0.2,0-0.5-0.1-0.7-0.1
		c-1.9,0-3.1,1.1-3.1,3.4v5.4h-3.5V600h3.3v2.1H496.7z"
        />
        <path
          class="st2"
          d="M501.6,608.1h3.3c0.2,1.1,1,1.7,2.4,1.7c1.2,0,1.9-0.5,1.9-1.3c0-1.1-1.4-1.2-3-1.5c-2.1-0.4-4.2-0.9-4.2-3.7
		c0-2.4,2.2-3.7,4.9-3.7c3.3,0,4.9,1.4,5.2,3.5h-3.2c-0.2-0.8-0.9-1.3-2-1.3s-1.7,0.4-1.7,1.2c0,0.9,1.3,1,2.9,1.3
		c2.1,0.4,4.5,0.9,4.5,3.9c0,2.5-2.2,3.9-5.3,3.9C503.9,612.1,501.8,610.5,501.6,608.1z"
        />
        <path
          class="st2"
          d="M513.7,605.9c0-3.5,2.5-6.2,6.2-6.2s6.2,2.7,6.2,6.2s-2.4,6.2-6.2,6.2C516.2,612.1,513.7,609.4,513.7,605.9z
		 M522.6,605.9c0-2.2-1-3.7-2.7-3.7c-1.8,0-2.7,1.5-2.7,3.7s0.9,3.7,2.7,3.7C521.6,609.6,522.6,608.1,522.6,605.9z"
        />
        <path
          class="st2"
          d="M531.1,602.1L531.1,602.1c0.8-1.6,1.9-2.3,3.3-2.3c0.3,0,0.6,0,0.7,0.1v3H535c-0.2,0-0.5-0.1-0.7-0.1
		c-1.9,0-3.1,1.1-3.1,3.4v5.4h-3.5V600h3.3L531.1,602.1L531.1,602.1z"
        />
        <path
          class="st2"
          d="M536.4,612.2h3.4c0.2,0.7,0.9,1.2,2.2,1.2c1.6,0,2.4-0.8,2.4-2.3v-0.2c0-0.6,0-1.1,0.1-1.5h-0.1
		c-0.7,1.1-1.7,1.7-3.2,1.7c-2.7,0-5.1-2-5.1-5.5c0-3.4,2-5.8,4.9-5.8c1.6,0,2.7,0.5,3.4,1.8l0,0V600h3.3v10.7c0,1.7-0.6,3-1.5,3.8
		c-1,0.9-2.5,1.3-4.2,1.3C538.8,615.8,536.8,614.5,536.4,612.2z M544.5,605.4c0-1.7-0.9-3-2.6-3c-1.5,0-2.5,1.2-2.5,3.1
		c0,1.8,0.9,3,2.5,3C543.8,608.4,544.5,607.1,544.5,605.4z"
        />
        <path
          class="st2"
          d="M549.5,605.9c0-3.5,2.4-6.2,6-6.2c1.7,0,3,0.6,4,1.5c1.3,1.3,1.9,3.3,1.9,5.6h-8.5c0.2,1.8,1.2,2.8,2.8,2.8
		c1.1,0,1.8-0.4,2.1-1.1h3.3c-0.6,2.1-2.5,3.7-5.5,3.7C551.9,612.1,549.5,609.4,549.5,605.9z M553,604.8h5c-0.1-1.6-1-2.6-2.4-2.6
		C554,602.2,553.2,603.2,553,604.8z"
        />
      </g>
      <g>
        <circle class="st9" cx="165.2" cy="159.6" r="23.8" />
        <path class="st10" d="M167.2,171.3h-4v-9.7h-9.7v-4h9.7V148h4v9.7h9.7v4h-9.7V171.3z" />
      </g>
      <path
        class="st0"
        d="M363.3,162.2l-29.2,29.2c-1.4,1.4-3.7,1.4-5.1,0l-3.4-3.4c-1.4-1.4-1.4-3.7,0-5.1l23.2-23.3l-23.2-23.3
	c-1.4-1.4-1.4-3.7,0-5.1l3.4-3.4c1.4-1.4,3.7-1.4,5.1,0l29.2,29.2C364.7,158.5,364.7,160.8,363.3,162.2z"
      />
      <g>
        <path
          class="st9"
          d="M531,59.2l-45.8-19.1c-1.3-0.5-2.8-0.8-4.3-0.8s-3,0.3-4.3,0.8l-45.8,19.1c-4.3,1.8-7.1,5.9-7.1,10.5
		c0,47.4,27.3,80,52.9,90.7c1.3,0.5,2.8,0.8,4.3,0.8s3-0.3,4.3-0.8c25.5-10.7,52.9-43.3,52.9-90.7C538.1,65.1,535.3,60.8,531,59.2z
		 M480.9,152h-0.3c-25.5-12.7-46.9-42.5-47.7-83.8l47.9-19.9h0.3L529,68.2C528,109.5,506.6,139.3,480.9,152z"
        />
        <path
          class="st0"
          d="M480.8,48.3l-47.9,19.9c1,41.3,22.4,71.1,47.9,83.8h0.3c25.5-12.7,47.1-42.6,47.9-83.8L480.8,48.3L480.8,48.3z
		"
        />
        <g>
          <path
            class="st10"
            d="M454.4,101.8L454.4,101.8v2.5h-5.1V79.6h5.2V85c0,1.4-0.2,3.1-0.2,4.1h0.2c1.3-1.8,2.9-2.9,5.2-2.9
			c4.5,0,7.6,4,7.6,9.4c0,5.8-3.1,9.4-7.6,9.4C457.5,104.9,455.5,103.9,454.4,101.8z M462,95.5c0-3.2-1.3-5.4-3.6-5.4
			c-2.7,0-4,2.3-4,5.4s1.4,5.2,4,5.2C460.7,100.7,462,98.7,462,95.5z"
          />
          <path
            class="st10"
            d="M477.5,79.6h6l9,24.7h-6l-1.8-4.7h-9l-1.4,4.7h-5.8L477.5,79.6z M477.1,95.7h6.3L482,91
			c-1.1-3.4-1.6-6.7-1.6-6.7l0,0c0,0-0.9,4.1-1.6,6.7L477.1,95.7z"
          />
          <path
            class="st10"
            d="M490.3,79.6h6l3.6,11.9c0.9,2.7,1.6,7.2,1.6,7.2l0,0c0,0,0.9-4.5,1.6-7.2l3.6-11.9h5.8l-8.1,24.7h-6
			L490.3,79.6z"
          />
        </g>
      </g>
      <rect x="14.8" y="53.9" class="st11" width="300.7" height="25" />
      <text transform="matrix(1 0 0 1 26.1924 68.7605)">
        <tspan x="0" y="0" class="st7 st12 st13">von</tspan>
        <tspan x="34.4" y="0" class="st7 st14 st13"></tspan>
        <tspan x="39.7" y="0" class="st7 st15 st13">292,00</tspan>
        <tspan x="109.9" y="0" class="st7 st14 st13"></tspan>
        <tspan x="115.2" y="0" class="st7 st12 st13">bis</tspan>
        <tspan x="147.9" y="0" class="st7 st15 st13">584,00 €</tspan>
        <tspan x="237.9" y="0" class="st7 st14 st13"></tspan>
        <tspan x="243.2" y="0" class="st7 st12 st13">mtl.</tspan>
      </text>
      <rect x="14.8" y="83.2" class="st11" width="300.7" height="31.5" />
      <text transform="matrix(1 0 0 1 106.3433 94.3691)">
        <tspan x="0" y="0" class="st7 st12 st16">Ohne Abzug von</tspan>
        <tspan x="30.3" y="18" class="st7 st12 st16">Steuern</tspan>
      </text>
      <rect x="14.8" y="205" class="st11" width="300.7" height="25" />
      <text transform="matrix(1 0 0 1 84.3814 219.8409)">
        <tspan x="0" y="0" class="st7 st12 st13">bis</tspan>
        <tspan x="27.6" y="0" class="st7 st14 st13"></tspan>
        <tspan x="32.9" y="0" class="st7 st15 st13">292,00 €</tspan>
        <tspan x="121.7" y="0" class="st7 st12 st13">mtl.</tspan>
      </text>
      <g>
        <rect x="14.8" y="237.3" class="st11" width="300.7" height="41.3" />
        <text transform="matrix(1 0 0 1 106.3433 248.4498)">
          <tspan x="0" y="0" class="st7 st12 st16">Ohne Abzug von</tspan>
          <tspan x="-55.5" y="18" class="st7 st12 st16">Steuern &amp; Sozialversicherungen</tspan>
        </text>
      </g>
    </svg>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 648.1 863"
      style="enable-background: new 0 0 648.1 863"
      xml:space="preserve"
      v-else
      class="en"
    >
      <path
        class="st0"
        d="M363.3,162.2l-29.2,29.2c-1.4,1.4-3.7,1.4-5.1,0l-3.4-3.4c-1.4-1.4-1.4-3.7,0-5.1l23.2-23.3l-23.2-23.3
	c-1.4-1.4-1.4-3.7,0-5.1l3.4-3.4c1.4-1.4,3.7-1.4,5.1,0l29.2,29.2C364.7,158.5,364.7,160.8,363.3,162.2z"
      />
      <g>
        <path
          class="st1"
          d="M621.2,394.2H344.6c-6.6,0-12-5.4-12-12V284c0-6.6,5.4-12,12-12h276.6c6.6,0,12,5.4,12,12v98.2
		C633.2,388.8,627.9,394.2,621.2,394.2z"
        />
      </g>
      <g>
        <path
          class="st1"
          d="M621.9,524.6H344c-6.3,0-11.4-5.1-11.4-11.4v-99.4c0-6.3,5.1-11.4,11.4-11.4h277.8c6.3,0,11.4,5.1,11.4,11.4
		v99.4C633.2,519.5,628.1,524.6,621.9,524.6z"
        />
      </g>
      <g>
        <path
          class="st2"
          d="M301.5,847.4H28.8c-7.7,0-14-6.3-14-14V286c0-7.7,6.3-14,14-14h272.7c7.7,0,14,6.3,14,14v547.4
		C315.5,841.1,309.2,847.4,301.5,847.4z"
        />
      </g>
      <g>
        <path
          class="st3"
          d="M622.4,847.4h-279c-6,0-10.9-4.9-10.9-10.9V543.7c0-6,4.9-10.9,10.9-10.9h278.9c6,0,10.9,4.9,10.9,10.9v292.8
		C633.2,842.5,628.4,847.4,622.4,847.4z"
        />
      </g>
      <g>
        <path
          class="st4"
          d="M90.9,556.6L90.9,556.6c-1.5,2.5-3.9,3.6-6.7,3.6c-3.3,0-6-1.2-7.8-3.2c-2-2.2-3.1-5.2-3.1-8.7
		c0-3.9,1.4-7.4,4-9.5c1.9-1.6,4.2-2.5,7.3-2.5c5.8,0,9.2,3.1,10.1,7.5h-5.3c-0.6-1.7-2-3.1-4.7-3.1c-4.1,0-6.1,3.3-6.1,7.7
		s2.4,7.7,6.1,7.7c3.3,0,5.2-2.2,5.2-4.5v-0.1h-4.7v-3.9H95v12.3h-3.7L90.9,556.6z"
        />
        <path
          class="st4"
          d="M103,546.1L103,546.1c1.1-2.3,2.6-3.2,4.7-3.2c0.5,0,0.8,0,1,0.1v4.2h-0.1c-0.3-0.1-0.7-0.1-1-0.1
		c-2.6,0-4.4,1.6-4.4,4.8v7.7h-4.9v-16.5h4.7C103,543.1,103,546.1,103,546.1z"
        />
        <path
          class="st4"
          d="M109.9,551.4c0-5,3.5-8.7,8.7-8.7s8.7,3.8,8.7,8.7s-3.4,8.7-8.7,8.7C113.4,560.2,109.9,556.4,109.9,551.4z
		 M122.4,551.4c0-3.1-1.4-5.3-3.8-5.3c-2.5,0-3.8,2.1-3.8,5.3c0,3.1,1.3,5.2,3.8,5.2C121,556.7,122.4,554.5,122.4,551.4z"
        />
        <path
          class="st4"
          d="M128.7,554.6h4.6c0.3,1.5,1.5,2.4,3.3,2.4c1.7,0,2.7-0.7,2.7-1.9c0-1.5-2-1.7-4.2-2.1
		c-2.9-0.6-5.9-1.3-5.9-5.1c0-3.4,3.1-5.1,6.9-5.1c4.6,0,6.9,2,7.3,4.9H139c-0.3-1.2-1.2-1.8-2.8-1.8c-1.5,0-2.4,0.6-2.4,1.7
		c0,1.2,1.8,1.4,4.1,1.8c2.9,0.5,6.3,1.3,6.3,5.5c0,3.6-3.2,5.4-7.4,5.4C131.9,560.2,129,557.9,128.7,554.6z"
        />
        <path
          class="st4"
          d="M145.6,554.6h4.6c0.3,1.5,1.5,2.4,3.3,2.4c1.7,0,2.7-0.7,2.7-1.9c0-1.5-2-1.7-4.2-2.1
		c-2.9-0.6-5.9-1.3-5.9-5.1c0-3.4,3.1-5.1,6.9-5.1c4.6,0,6.9,2,7.3,4.9h-4.5c-0.3-1.2-1.2-1.8-2.8-1.8c-1.5,0-2.4,0.6-2.4,1.7
		c0,1.2,1.8,1.4,4.1,1.8c2.9,0.5,6.3,1.3,6.3,5.5c0,3.6-3.2,5.4-7.4,5.4C148.7,560.2,145.8,557.9,145.6,554.6z"
        />
        <path
          class="st4"
          d="M169.8,554.6h4.6c0.3,1.5,1.5,2.4,3.3,2.4c1.7,0,2.7-0.7,2.7-1.9c0-1.5-2-1.7-4.2-2.1
		c-2.9-0.6-5.9-1.3-5.9-5.1c0-3.4,3.1-5.1,6.9-5.1c4.6,0,6.9,2,7.3,4.9H180c-0.3-1.2-1.2-1.8-2.8-1.8c-1.5,0-2.4,0.6-2.4,1.7
		c0,1.2,1.8,1.4,4.1,1.8c2.9,0.5,6.3,1.3,6.3,5.5c0,3.6-3.2,5.4-7.4,5.4C172.9,560.2,170,557.9,169.8,554.6z"
        />
        <path
          class="st4"
          d="M197.6,557.6L197.6,557.6c-1.1,1.5-2.5,2.4-5.1,2.4c-3.3,0-5.6-1.7-5.6-5c0-3.7,2.9-4.6,6.5-5
		c2.9-0.4,4.2-0.9,4.2-2.1c0-1.2-0.8-2-2.4-2c-1.9,0-2.8,0.9-2.9,2.2h-4.5c0.1-2.8,2.4-5.5,7.3-5.5c2.1,0,3.6,0.3,4.7,1
		c1.7,1,2.5,2.7,2.5,4.9v8.5c0,1.3,0.2,2,0.6,2.3v0.3h-4.7C197.9,559.3,197.6,558.6,197.6,557.6z M197.6,553.7v-2.1
		c-0.7,0.4-1.8,0.7-2.9,0.9c-2.1,0.5-3.2,1-3.2,2.4s1.1,2,2.4,2C196.2,556.9,197.6,555.5,197.6,553.7z"
        />
        <path class="st4" d="M205.6,536.6h4.9v23.1h-4.9V536.6z" />
        <path
          class="st4"
          d="M223.7,557.6L223.7,557.6c-1.1,1.5-2.5,2.4-5.1,2.4c-3.3,0-5.6-1.7-5.6-5c0-3.7,2.9-4.6,6.5-5
		c2.9-0.4,4.2-0.9,4.2-2.1c0-1.2-0.8-2-2.4-2c-1.9,0-2.8,0.9-2.9,2.2h-4.5c0.1-2.8,2.4-5.5,7.3-5.5c2.1,0,3.6,0.3,4.7,1
		c1.7,1,2.5,2.7,2.5,4.9v8.5c0,1.3,0.2,2,0.6,2.3v0.3h-4.7C224.1,559.3,223.8,558.6,223.7,557.6z M223.8,553.7v-2.1
		c-0.7,0.4-1.8,0.7-2.9,0.9c-2.1,0.5-3.2,1-3.2,2.4s1.1,2,2.4,2C222.4,556.9,223.8,555.5,223.8,553.7z"
        />
        <path
          class="st4"
          d="M236.4,546.1L236.4,546.1c1.1-2.3,2.6-3.2,4.7-3.2c0.5,0,0.8,0,1,0.1v4.2H242c-0.3-0.1-0.7-0.1-1-0.1
		c-2.6,0-4.4,1.6-4.4,4.8v7.7h-4.9v-16.5h4.7L236.4,546.1L236.4,546.1z"
        />
        <path
          class="st4"
          d="M245.1,565.1v-3.8h0.2c0.1,0,1.5,0,1.6,0c1.5,0,2.3-0.6,2.4-1.7c0-0.6-0.3-1.8-0.9-3.3l-5.1-13.2h5.1l2.1,6.3
		c0.7,2.2,1.4,5.7,1.4,5.7h0.1c0,0,0.7-3.5,1.5-5.7l2-6.3h4.8l-5.9,16.9c-1.3,3.8-2.9,5.1-6,5.1
		C248.2,565.2,245.2,565.1,245.1,565.1z"
        />
      </g>
      <g>
        <path
          class="st4"
          d="M408.8,695.4h5.4l6.1,10c1.4,2.3,3.2,6.1,3.2,6.1h0.1c0,0-0.3-4.1-0.3-6.8v-9.3h5.1v23.1H423l-6-9.9
		c-1.4-2.3-3.2-6.2-3.2-6.2h-0.1c0,0,0.3,4.2,0.3,6.8v9.3h-5.1v-23.1H408.8z"
        />
        <path
          class="st4"
          d="M431,710.2c0-5,3.4-8.7,8.5-8.7c2.4,0,4.2,0.8,5.7,2.1c1.9,1.8,2.7,4.6,2.7,7.8h-12c0.3,2.5,1.6,4,4,4
		c1.5,0,2.5-0.6,3-1.6h4.7c-0.8,2.9-3.5,5.1-7.8,5.1C434.3,718.9,431,715.2,431,710.2z M435.8,708.6h7c-0.2-2.3-1.5-3.7-3.4-3.7
		C437.2,704.9,436.1,706.4,435.8,708.6z"
        />
        <path
          class="st4"
          d="M449,702h2.5v-5.3h4.8v5.3h3.2v3.1h-3.2v8.2c0,1.2,0.7,1.6,1.8,1.6c0.3,0,0.8,0,1.1-0.1h0.2v3.6
		c-0.5,0.1-1.5,0.2-2.8,0.2c-3.1,0-5.1-1-5.1-4.3v-9.2H449V702z"
        />
        <path
          class="st4"
          d="M468.4,713.4h4.6c0.3,1.5,1.5,2.4,3.3,2.4c1.7,0,2.7-0.7,2.7-1.9c0-1.5-2-1.7-4.2-2.1
		c-2.9-0.6-5.9-1.3-5.9-5.1c0-3.4,3.1-5.1,6.9-5.1c4.6,0,6.9,2,7.3,4.9h-4.5c-0.3-1.2-1.2-1.8-2.8-1.8c-1.5,0-2.4,0.6-2.4,1.7
		c0,1.2,1.8,1.4,4.1,1.8c2.9,0.5,6.3,1.3,6.3,5.5c0,3.6-3.2,5.4-7.4,5.4C471.5,719,468.6,716.7,468.4,713.4z"
        />
        <path
          class="st4"
          d="M496.2,716.4L496.2,716.4c-1.1,1.5-2.5,2.4-5.1,2.4c-3.3,0-5.6-1.7-5.6-5c0-3.7,2.9-4.6,6.5-5
		c2.9-0.4,4.2-0.9,4.2-2.1c0-1.2-0.8-2-2.4-2c-1.9,0-2.8,0.9-2.9,2.2h-4.5c0.1-2.8,2.4-5.5,7.3-5.5c2.1,0,3.6,0.3,4.7,1
		c1.7,1,2.5,2.7,2.5,4.9v8.5c0,1.3,0.2,2,0.6,2.3v0.3h-4.7C496.5,718.1,496.3,717.4,496.2,716.4z M496.2,712.5v-2.1
		c-0.7,0.4-1.8,0.7-2.9,0.9c-2.1,0.5-3.2,1-3.2,2.4s1.1,2,2.4,2C494.8,715.7,496.2,714.3,496.2,712.5z"
        />
        <path class="st4" d="M504.2,695.4h4.9v23.1h-4.9V695.4z" />
        <path
          class="st4"
          d="M522.4,716.4L522.4,716.4c-1.1,1.5-2.5,2.4-5.1,2.4c-3.3,0-5.6-1.7-5.6-5c0-3.7,2.9-4.6,6.5-5
		c2.9-0.4,4.2-0.9,4.2-2.1c0-1.2-0.8-2-2.4-2c-1.9,0-2.8,0.9-2.9,2.2h-4.5c0.1-2.8,2.4-5.5,7.3-5.5c2.1,0,3.6,0.3,4.7,1
		c1.7,1,2.5,2.7,2.5,4.9v8.5c0,1.3,0.2,2,0.6,2.3v0.3H523C522.7,718.1,522.5,717.4,522.4,716.4z M522.4,712.5v-2.1
		c-0.7,0.4-1.8,0.7-2.9,0.9c-2.1,0.5-3.2,1-3.2,2.4s1.1,2,2.4,2C521,715.7,522.4,714.3,522.4,712.5z"
        />
        <path
          class="st4"
          d="M535,704.9L535,704.9c1.1-2.3,2.6-3.2,4.7-3.2c0.5,0,0.8,0,1,0.1v4.2h-0.1c-0.3-0.1-0.7-0.1-1-0.1
		c-2.6,0-4.4,1.6-4.4,4.8v7.7h-4.9V702h4.7V704.9z"
        />
        <path
          class="st4"
          d="M543.7,723.9v-3.8h0.2c0.1,0,1.5,0,1.6,0c1.5,0,2.3-0.6,2.4-1.7c0-0.6-0.3-1.8-0.9-3.3l-5.1-13.2h5.1l2.1,6.3
		c0.7,2.2,1.4,5.7,1.4,5.7h0.1c0,0,0.7-3.5,1.5-5.7l2-6.3h4.8l-5.9,16.9c-1.3,3.8-2.9,5.1-6,5.1C546.8,724,543.8,723.9,543.7,723.9z
		"
        />
      </g>
      <g>
        <path class="st5" d="M449.7,453.2h14.7v3.5h-5.3v14.4H455v-14.4h-5.3V453.2z" />
        <path
          class="st5"
          d="M471.3,469.5L471.3,469.5c-0.9,1.2-1.9,1.9-3.9,1.9c-2.5,0-4.4-1.3-4.4-3.9c0-2.8,2.2-3.6,5-3.9
		c2.3-0.3,3.2-0.7,3.2-1.6s-0.6-1.5-1.9-1.5c-1.4,0-2.1,0.7-2.2,1.7h-3.5c0.1-2.2,1.9-4.2,5.7-4.2c1.6,0,2.8,0.2,3.7,0.8
		c1.3,0.8,1.9,2.1,1.9,3.8v6.6c0,1,0.1,1.6,0.5,1.8v0.2h-3.7C471.6,470.8,471.4,470.2,471.3,469.5z M471.4,466.5v-1.7
		c-0.6,0.3-1.4,0.5-2.2,0.7c-1.7,0.4-2.4,0.8-2.4,1.9s0.8,1.5,1.9,1.5C470.3,468.9,471.4,467.9,471.4,466.5z"
        />
        <path
          class="st5"
          d="M481.1,464.4l-4.1-6.1h4.3l0.7,1.2c0.6,1.1,1.3,2.7,1.3,2.7l0,0c0,0,0.7-1.7,1.3-2.7l0.7-1.2h4l-3.9,6l4.5,6.8
		h-4.4l-1-1.6c-0.6-1.1-1.4-2.8-1.4-2.8l0,0c0,0-0.8,1.8-1.5,2.8l-1,1.6h-4.1L481.1,464.4z"
        />
        <path
          class="st5"
          d="M490.2,464.7c0-3.8,2.7-6.8,6.6-6.8c1.8,0,3.3,0.6,4.4,1.6c1.4,1.4,2.1,3.6,2.1,6.1H494
		c0.2,1.9,1.3,3.1,3.1,3.1c1.2,0,1.9-0.5,2.3-1.2h3.6c-0.6,2.3-2.7,4-6,4C492.7,471.4,490.2,468.5,490.2,464.7z M493.9,463.4h5.4
		c-0.1-1.8-1.1-2.9-2.6-2.9C495,460.6,494.1,461.7,493.9,463.4z"
        />
        <path
          class="st5"
          d="M504.3,467.1h3.6c0.2,1.2,1.1,1.9,2.6,1.9c1.3,0,2.1-0.6,2.1-1.5c0-1.2-1.5-1.3-3.3-1.6c-2.3-0.4-4.6-1-4.6-4
		c0-2.6,2.4-4,5.4-4c3.6,0,5.3,1.5,5.7,3.8h-3.5c-0.2-0.9-1-1.4-2.2-1.4s-1.9,0.5-1.9,1.3c0,1,1.4,1.1,3.1,1.4
		c2.3,0.4,4.9,1,4.9,4.2c0,2.8-2.4,4.2-5.8,4.2C506.7,471.5,504.4,469.7,504.3,467.1z"
        />
      </g>
      <g>
        <path
          class="st5"
          d="M394.5,333h4.1c0.2,1.9,1.5,2.8,3.8,2.8c1.6,0,3.1-0.6,3.1-2.1c0-1.6-1.5-1.9-4.1-2.5
		c-3.5-0.8-6.3-1.7-6.3-5.4c0-3.7,3-5.4,7-5.4s6.7,1.8,7,5.3h-4c-0.2-1.4-1.3-2.2-3.1-2.2c-1.7,0-2.8,0.7-2.8,1.8
		c0,1.4,1.5,1.7,4.3,2.3c3.2,0.7,6.2,1.8,6.2,5.5s-3.1,5.8-7.2,5.8C397.7,339.1,394.6,336.9,394.5,333z"
        />
        <path
          class="st5"
          d="M411.3,332.3c0-3.8,2.7-6.8,6.8-6.8c4.1,0,6.7,2.9,6.7,6.8c0,3.8-2.7,6.8-6.7,6.8
		C413.9,339.1,411.3,336.1,411.3,332.3z M420.9,332.3c0-2.4-1.1-4.1-2.9-4.1c-1.9,0-2.9,1.7-2.9,4.1s1,4,2.9,4
		S420.9,334.7,420.9,332.3z"
        />
        <path
          class="st5"
          d="M426,332.3c0-3.8,2.6-6.8,6.6-6.8c3.4,0,5.5,1.9,6,4.7H435c-0.3-1.2-1-1.9-2.3-1.9c-1.9,0-2.9,1.6-2.9,4
		c0,2.3,1,3.9,2.9,3.9c1.4,0,2.2-0.8,2.4-2.3h3.6c-0.1,2.9-2.4,5.2-6,5.2C428.7,339.1,426,336.1,426,332.3z"
        />
        <path class="st5" d="M440.5,320.8h3.8v3.3h-3.8V320.8z M440.5,325.9h3.8v12.8h-3.8V325.9z" />
        <path
          class="st5"
          d="M454.5,337.1L454.5,337.1c-0.9,1.2-1.9,1.9-3.9,1.9c-2.5,0-4.4-1.3-4.4-3.9c0-2.8,2.2-3.6,5-3.9
		c2.3-0.3,3.2-0.7,3.2-1.6s-0.6-1.5-1.9-1.5c-1.4,0-2.1,0.7-2.2,1.7h-3.5c0.1-2.2,1.9-4.2,5.7-4.2c1.6,0,2.8,0.2,3.7,0.8
		c1.3,0.8,1.9,2.1,1.9,3.8v6.6c0,1,0.1,1.6,0.5,1.8v0.2h-3.7C454.7,338.4,454.6,337.8,454.5,337.1z M454.5,334.1v-1.7
		c-0.6,0.3-1.4,0.5-2.2,0.7c-1.7,0.4-2.4,0.8-2.4,1.9s0.8,1.5,1.9,1.5C453.4,336.5,454.5,335.5,454.5,334.1z"
        />
        <path class="st5" d="M460.7,320.8h3.8v17.9h-3.8V320.8z" />
        <path
          class="st5"
          d="M472.2,333h4.1c0.2,1.9,1.5,2.8,3.8,2.8c1.6,0,3.1-0.6,3.1-2.1c0-1.6-1.5-1.9-4.1-2.5
		c-3.5-0.8-6.3-1.7-6.3-5.4c0-3.7,3-5.4,7-5.4s6.7,1.8,7,5.3h-4c-0.2-1.4-1.3-2.2-3.1-2.2c-1.7,0-2.8,0.7-2.8,1.8
		c0,1.4,1.5,1.7,4.3,2.3c3.2,0.7,6.2,1.8,6.2,5.5s-3.1,5.8-7.2,5.8C475.3,339.1,472.3,336.9,472.2,333z"
        />
        <path
          class="st5"
          d="M488.9,332.3c0-3.8,2.7-6.8,6.6-6.8c1.8,0,3.3,0.6,4.4,1.6c1.4,1.4,2.1,3.6,2.1,6.1h-9.3
		c0.2,1.9,1.3,3.1,3.1,3.1c1.2,0,1.9-0.5,2.3-1.2h3.6c-0.6,2.3-2.7,4-6,4C491.5,339,488.9,336.1,488.9,332.3z M492.6,331h5.4
		c-0.1-1.8-1.1-2.9-2.6-2.9C493.7,328.2,492.9,329.3,492.6,331z"
        />
        <path
          class="st5"
          d="M503.2,332.3c0-3.8,2.6-6.8,6.6-6.8c3.4,0,5.5,1.9,6,4.7h-3.6c-0.3-1.2-1-1.9-2.3-1.9c-1.9,0-2.9,1.6-2.9,4
		c0,2.3,1,3.9,2.9,3.9c1.4,0,2.2-0.8,2.4-2.3h3.6c-0.1,2.9-2.4,5.2-6,5.2C505.8,339.1,503.2,336.1,503.2,332.3z"
        />
        <path
          class="st5"
          d="M525.9,338.7v-1.8h-0.1c-0.9,1.5-2.1,2.2-3.8,2.2c-2.9,0-4.5-1.9-4.5-4.7V326h3.8v7.7c0,1.4,0.7,2.2,2,2.2
		c1.5,0,2.4-1.2,2.4-2.7V326h3.7v12.8h-3.5V338.7z"
        />
        <path
          class="st5"
          d="M535.6,328.2L535.6,328.2c0.9-1.8,2-2.5,3.6-2.5c0.4,0,0.6,0,0.8,0.1v3.3h0c-0.3,0-0.5-0.1-0.8-0.1
		c-2,0-3.4,1.2-3.4,3.7v5.9H532v-12.8h3.6V328.2z"
        />
        <path class="st5" d="M541.8,320.8h3.8v3.3h-3.8V320.8z M541.9,325.9h3.8v12.8h-3.8V325.9z" />
        <path
          class="st5"
          d="M547.4,325.9h1.9v-4.1h3.7v4.1h2.5v2.4H553v6.3c0,1,0.5,1.2,1.4,1.2c0.2,0,0.6,0,0.9,0h0.2v2.8
		c-0.4,0.1-1.2,0.2-2.2,0.2c-2.4,0-4-0.8-4-3.4v-7.2h-1.9V325.9z"
        />
        <path
          class="st5"
          d="M558.1,342.9v-3h0.2c0,0,1.1,0,1.2,0c1.2,0,1.8-0.4,1.8-1.3c0-0.4-0.2-1.4-0.7-2.6l-4-10.2h4l1.6,4.9
		c0.6,1.7,1.1,4.4,1.1,4.4l0,0c0,0,0.6-2.7,1.1-4.4l1.6-4.9h3.7l-4.6,13.1c-1,3-2.2,4-4.7,4C560.5,342.9,558.2,342.9,558.1,342.9z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M303.5,307.8H26.8c-6.6,0-12-5.4-12-12V176.7c0-6.6,5.4-12,12-12h276.6c6.6,0,12,5.4,12,12v119.2
		C315.5,302.5,310.1,307.8,303.5,307.8z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M303.5,156.1H26.8c-6.6,0-12-5.4-12-12V24.9c0-6.6,5.4-12,12-12h276.6c6.6,0,12,5.4,12,12v119.2
		C315.5,150.7,310.1,156.1,303.5,156.1z"
        />
      </g>
      <g>
        <path
          class="st6"
          d="M457.5,200.5v-2.2h-0.1c-1.1,1.6-2.5,2.6-4.9,2.6c-3.3,0-5.5-2-5.5-5.4v-11.1h2.8v10.9c0,2,1.3,3.2,3.5,3.2
		c2.5,0,4-1.9,4-4.4v-9.8h2.8v16.1L457.5,200.5L457.5,200.5z"
        />
        <path
          class="st6"
          d="M464.1,184.4h2.8v2.5h0.1c1.2-1.8,2.8-2.9,5.1-2.9c4.1,0,6.9,3.2,6.9,8.5c0,5.2-2.7,8.5-7,8.5
		c-2.2,0-4-0.9-5.1-2.6h-0.1c0,0.6,0.1,1.5,0.1,2.7v4.9h-2.8V184.4z M476.2,192.5c0-3.5-1.5-6.2-4.4-6.2c-3.4,0-5,2.8-5,6.2
		s1.4,6.1,4.9,6.1C474.7,198.6,476.2,196,476.2,192.5z"
        />
        <path
          class="st6"
          d="M489,184.4h2.5v-5.1h2.8v5.1h3.3v2.2h-3.3v10.2c0,1.2,0.6,1.5,1.6,1.5c0.5,0,1.1-0.1,1.4-0.2h0.1v2.3
		c-0.7,0.2-1.5,0.3-2.2,0.3c-2.2,0-3.7-0.9-3.7-3.3v-10.8H489V184.4z"
        />
        <path
          class="st6"
          d="M499.5,192.4c0-4.7,3-8.5,7.9-8.5s7.8,3.8,7.8,8.5s-3,8.5-7.8,8.5C502.5,200.9,499.5,197.1,499.5,192.4z
		 M512.4,192.4c0-3.5-1.7-6.2-5-6.2s-5,2.8-5,6.2s1.7,6.2,5,6.2S512.4,195.9,512.4,192.4z"
        />
        <path
          class="st6"
          d="M372.8,230.4h4.8c0.3,1.6,1.7,2.6,3.7,2.6c2.2,0,3.8-1.5,3.8-3.8s-1.6-3.7-3.7-3.7c-1.5,0-2.7,0.6-3.3,1.4
		h-4.6l2.2-11.9h13v3.9h-9.5l-1,4.7h0.1c1-1.1,2.3-1.7,4.4-1.7c3.9,0,7.3,2.8,7.3,7.3c0,4.6-3.5,7.6-8.5,7.6
		C376.7,237,373.1,234.5,372.8,230.4z"
        />
        <path
          class="st6"
          d="M391.9,230.4c0-2.5,1.5-4.5,4.2-5.4v-0.1c-2.3-0.8-3.3-2.4-3.3-4.4c0-3.5,3.1-5.9,7.9-5.9s7.9,2.2,7.9,5.8
		c0,1.9-1.1,3.5-3.3,4.3v0.1c2.8,0.8,4.3,2.9,4.3,5.4c0,4.3-3.7,6.7-8.8,6.7C395.7,237,391.9,234.5,391.9,230.4z M404.6,230.1
		c0-2.1-1.4-3.4-3.8-3.4s-3.9,1.3-3.9,3.4c0,2,1.5,3.2,3.9,3.2C403.1,233.3,404.6,232,404.6,230.1z M404,220.8
		c0-1.6-1.2-2.8-3.3-2.8c-2.1,0-3.3,1.1-3.3,2.8c0,1.6,1.2,2.7,3.3,2.7S404,222.4,404,220.8z"
        />
        <path
          class="st6"
          d="M422.3,231.6h-10v-4.4l10-12.4h4.7V228h3.2v3.6H427v4.8h-4.7V231.6z M418.7,228h3.7v-5.2
		c0-1.3,0.2-3.3,0.2-3.3h-0.1c0,0-1.2,1.9-2.1,3l-2.3,2.9c-0.9,1.1-2.5,2.7-2.5,2.7v0.1C415.6,228.2,417.5,228,418.7,228z"
        />
        <path
          class="st6"
          d="M431.7,239.8c1.4-0.4,2.3-1.5,2.3-3.3v-0.1h-2.3v-4.9h5v4.6c0,3.8-2.2,5.5-4.9,5.7v-2H431.7z"
        />
        <path
          class="st6"
          d="M438.6,225.8c0-6.8,3.8-11.2,9.3-11.2c5.6,0,9.3,4.3,9.3,11.2c0,6.8-3.7,11.2-9.3,11.2
		C442.4,237,438.6,232.6,438.6,225.8z M452.2,225.8c0-4.4-1.2-7.3-4.3-7.3c-3,0-4.3,3-4.3,7.3s1.3,7.3,4.3,7.3
		C451,233.1,452.2,230.2,452.2,225.8z"
        />
        <path
          class="st6"
          d="M459.4,225.8c0-6.8,3.8-11.2,9.3-11.2c5.6,0,9.3,4.3,9.3,11.2c0,6.8-3.7,11.2-9.3,11.2
		S459.4,232.6,459.4,225.8z M472.9,225.8c0-4.4-1.2-7.3-4.3-7.3c-3,0-4.3,3-4.3,7.3s1.3,7.3,4.3,7.3
		C471.7,233.1,472.9,230.2,472.9,225.8z"
        />
        <path
          class="st6"
          d="M487.1,222.9h1.6c1-4.9,4.4-8.2,9.1-8.2c5,0,7.8,3.1,8.2,7.4h-4.8c-0.3-2.2-1.4-3.6-3.5-3.6
		c-2.2,0-3.5,1.7-4,4.4h5v1.9h-5.2c0,0.3,0,0.7,0,1c0,0.4,0,0.8,0,1.2h5.2v2h-5c0.6,2.6,2,4,4.2,4s3.4-1.7,3.6-4h4.7
		c-0.2,4.6-3.1,8-8.3,8c-4.7,0-8.2-3.1-9.2-8h-1.6v-2h1.4c0-0.4,0-0.8,0-1.2c0-0.3,0-0.7,0-1h-1.4L487.1,222.9L487.1,222.9z"
        />
        <path
          class="st6"
          d="M516,220.4h2.8v2.4h0.1c1-1.7,2.7-2.8,4.8-2.8c2,0,3.5,1,4.2,3h0.1c1.1-2,3-3,5-3c2.9,0,5,1.9,5,5.2v11.2h-2.8
		v-11c0-2-1.3-3.1-2.9-3.1c-2,0-3.8,1.8-3.8,4.2v9.9h-2.8v-11c0-2-1.1-3.1-2.8-3.1c-1.9,0-4,1.8-4,4.2v9.9H516V220.4z"
        />
        <path
          class="st6"
          d="M540.3,220.4h2.5v-5.1h2.8v5.1h3.3v2.2h-3.3v10.2c0,1.2,0.6,1.5,1.7,1.5c0.5,0,1.1-0.1,1.4-0.2h0.1v2.3
		c-0.8,0.2-1.5,0.3-2.2,0.3c-2.2,0-3.7-0.9-3.7-3.3v-10.8h-2.5L540.3,220.4L540.3,220.4z"
        />
        <path
          class="st6"
          d="M554.5,222.7L554.5,222.7c1.3-1.7,2.9-2.7,5.2-2.7c3.4,0,5.4,1.9,5.4,5.2v11.2h-2.8v-10.9
		c0-2.1-1.3-3.2-3.5-3.2c-2.5,0-4.3,1.8-4.3,4.3v9.8h-2.8v-22.3h2.8v5.9C554.6,220.8,554.6,221.9,554.5,222.7z"
        />
        <path class="st6" d="M569,214.2h2.8v22.3H569V214.2z" />
        <path
          class="st6"
          d="M575.4,241.8v-2.4h0.1c0.3,0.2,1,0.3,1.7,0.3c1.1,0,1.8-0.4,2.4-1.9c0-0.1,0.6-1.9,0.6-2l-6.1-15.3h3l3.3,8.9
		c0.7,1.8,1.3,4.4,1.3,4.4h0.1c0,0,0.8-2.5,1.4-4.4l3.2-8.9h2.9l-6.8,17.6c-1.3,3.4-2.6,4.1-4.7,4.1
		C576.8,242.1,575.8,241.9,575.4,241.8z"
        />
      </g>
      <g>
        <path
          class="st5"
          d="M493.9,379.1c1.4-2.3,3-4.6,4.7-7c0.7-1,0.7-2,0.2-2.9c-0.5-1.1-1.4-1.6-2.5-1.7c-0.1,0-0.2,0-0.3,0
		c-8.6,0-17.3,0-25.9,0c-1.7,0-2.9,1.1-3.2,2.7c-0.1,0.7,0.1,1.3,0.4,1.9c1.6,2.3,3.1,4.5,4.7,6.9h21.9L493.9,379.1z"
        />
        <path
          class="st5"
          d="M515,415.9c-0.2-3.8-1.2-7.5-2.6-11.1c-1.7-4.4-4.3-8.3-7.4-11.8s-6.7-6.5-10.5-9.2h-23.2
		c-2.9,2-5.7,4.1-8.2,6.7c-5,5-8.8,10.7-10.9,17.6c-0.8,2.5-1.3,5-1.5,7.6c0,0.7-0.1,1.3-0.1,2c0,0.1,0,0.2,0,0.3
		c0,0.5,0.1,1,0.1,1.5c0,2.6,0.9,5,2.4,7.1c2.3,3.1,5.5,4.7,9.2,5.3c0.7,0.1,1.3,0.1,2.1,0.1c12.5,0,25.2,0,37.7,0
		c5.3-0.3,9.5-2.7,12-7.5c0.8-1.4,1.1-2.9,1.2-4.6c0-0.1,0-0.3,0-0.4c0-0.5,0-1.2,0-1.7C515.1,417.1,515.1,416.6,515,415.9z"
        />
        <polygon class="st7" points="493.9,379.1 493.9,378.9 472,378.9 471.8,378.9 471.3,383.9 494.5,383.9 	" />
        <path
          class="st7"
          d="M491.1,416.8c-0.1-0.4-0.5-0.7-0.9-0.7c-0.7,0.1-1.7,0.3-2.9,0.3c-3.6,0-6.5-2.3-7.9-5.5h7.3
		c0.3,0,0.7-0.2,0.8-0.7l0.4-1.9c0.1-0.5-0.2-1-0.8-1h-8.6c-0.1-0.9-0.1-1.9,0-2.7h9.4c0.3,0,0.7-0.2,0.8-0.7l0.4-1.9
		c0.1-0.5-0.2-1-0.8-1h-8.8c1.3-2.8,4-4.8,7.5-4.8c0.9,0,1.9,0.1,2.4,0.2c0.4,0.1,0.8-0.1,0.9-0.5l0.8-2.8c0.1-0.4-0.1-0.9-0.5-1
		c-0.8-0.1-2.1-0.3-3.4-0.3c-6.2,0-11.2,3.8-13.1,9.3H472c-0.4,0-0.8,0.3-0.8,0.8v1.9c0,0.4,0.3,0.8,0.8,0.8h1.4
		c-0.1,0.9-0.1,1.9,0,2.7H472c-0.4,0-0.8,0.3-0.8,0.8v1.9c0,0.4,0.3,0.8,0.8,0.8h2c1.6,5.8,6.7,10,13.3,10c1.6,0,3.2-0.3,3.9-0.5
		c0.4-0.1,0.7-0.5,0.5-0.9L491.1,416.8z"
        />
      </g>
      <g>
        <path
          class="st4"
          d="M622.3,649.6H343.5c-6,0-10.9-4.9-10.9-10.9v-94.9c0-6,4.9-10.9,10.9-10.9h278.8c6,0,10.9,4.9,10.9,10.9v94.9
		C633.2,644.7,628.4,649.6,622.3,649.6z"
        />
      </g>
      <g>
        <path
          class="st2"
          d="M451.9,580.1h3.1c0.2,2.1,1.6,3.2,4.1,3.2c1.8,0,3.3-0.8,3.3-2.4c0-1.8-1.6-2.2-4.2-2.8
		c-3.1-0.7-5.6-1.5-5.6-4.8c0-3.1,2.6-4.7,6.1-4.7c3.8,0,6,1.8,6.3,4.8h-3c-0.2-1.6-1.5-2.5-3.4-2.5c-1.8,0-3,0.8-3,2.1
		c0,1.5,1.6,1.9,4.1,2.4c3,0.7,5.8,1.6,5.8,5.1c0,3.3-2.7,5.1-6.4,5.1C454.6,585.7,452.1,583.5,451.9,580.1z"
        />
        <path
          class="st2"
          d="M467.1,579.5c0-3.5,2.4-6.2,6-6.2s6,2.7,6,6.2s-2.3,6.2-6,6.2S467.1,583,467.1,579.5z M476.2,579.5
		c0-2.4-1.1-4.1-3.1-4.1c-2.1,0-3.1,1.8-3.1,4.1c0,2.4,1.1,4.1,3.1,4.1S476.2,581.9,476.2,579.5z"
        />
        <path
          class="st2"
          d="M485.9,573.6h1.8v-1.3c0-1.2,0.3-2,0.9-2.6c0.7-0.6,1.7-0.8,3.1-0.8c0.4,0,0.8,0,1.3,0.1v2.1
		c-1.8-0.1-2.4,0.1-2.4,1.4v1.1h2.4v1.9h-2.4v9.8h-2.8v-9.8H486v-1.9H485.9z"
        />
        <path
          class="st2"
          d="M501.8,583.8L501.8,583.8c-0.8,1-1.9,1.8-3.7,1.8c-2.3,0-4-1.2-4-3.5c0-2.7,2-3.3,4.8-3.6
		c1.9-0.2,2.9-0.5,2.9-1.6c0-1-0.7-1.7-2.1-1.7c-1.6,0-2.3,0.7-2.4,1.9h-2.6c0.1-2,1.7-3.8,5-3.8c1.3,0,2.4,0.2,3.1,0.7
		c1.1,0.7,1.7,1.8,1.7,3.3v6.2c0,1,0.2,1.5,0.4,1.7v0.2h-2.7C502,585.1,501.9,584.6,501.8,583.8z M501.9,581.1v-1.7
		c-0.5,0.4-1.4,0.6-2.3,0.8c-1.7,0.3-2.7,0.7-2.7,2s0.9,1.6,2,1.6C500.8,583.7,501.9,582.4,501.9,581.1z"
        />
        <path
          class="st2"
          d="M509.8,575.7L509.8,575.7c0.8-1.5,1.9-2.3,3.4-2.3c0.3,0,0.6,0,0.7,0.1v2.5h-0.1c-0.3-0.1-0.5-0.1-0.8-0.1
		c-1.8,0-3.2,1.2-3.2,3.5v5.9H507v-11.8h2.7L509.8,575.7L509.8,575.7z"
        />
        <path
          class="st2"
          d="M373,595.4h7.8c3.2,0,5.6,1.7,5.6,4.7c0,1.9-0.9,3.3-3,4v0.1c1.6,0.5,2.4,1.4,2.6,3.2c0.3,2.2,0.2,4.1,0.7,4.3
		v0.2H383c-0.4-0.2-0.4-2-0.6-3.8c-0.2-1.6-1-2.5-2.8-2.5h-2.8v6.2H373V595.4z M376.7,602.7h3.3c1.7,0,2.6-0.9,2.6-2.2
		s-0.8-2.2-2.5-2.2h-3.4V602.7z"
        />
        <path
          class="st2"
          d="M387.8,605.9c0-3.5,2.4-6.2,6-6.2c1.7,0,3,0.6,4,1.5c1.3,1.3,1.9,3.3,1.9,5.6h-8.5c0.2,1.8,1.2,2.8,2.8,2.8
		c1.1,0,1.8-0.4,2.1-1.1h3.3c-0.6,2.1-2.5,3.7-5.5,3.7C390.1,612.1,387.8,609.4,387.8,605.9z M391.2,604.8h5c-0.1-1.6-1-2.6-2.4-2.6
		C392.2,602.2,391.4,603.2,391.2,604.8z"
        />
        <path
          class="st2"
          d="M400.5,600h1.8v-3.7h3.4v3.7h2.3v2.2h-2.3v5.8c0,0.9,0.5,1.1,1.3,1.1c0.2,0,0.6,0,0.8,0h0.2v2.6
		c-0.4,0.1-1.1,0.2-2,0.2c-2.2,0-3.7-0.7-3.7-3.1v-6.6h-1.8L400.5,600L400.5,600z"
        />
        <path class="st2" d="M409.8,595.4h3.5v3h-3.5V595.4z M409.8,600h3.5v11.7h-3.5V600z" />
        <path
          class="st2"
          d="M418.8,602.1L418.8,602.1c0.8-1.6,1.9-2.3,3.3-2.3c0.3,0,0.6,0,0.7,0.1v3h-0.1c-0.2,0-0.5-0.1-0.7-0.1
		c-1.9,0-3.1,1.1-3.1,3.4v5.4h-3.5V600h3.3v2.1H418.8z"
        />
        <path
          class="st2"
          d="M423.8,605.9c0-3.5,2.4-6.2,6-6.2c1.7,0,3,0.6,4,1.5c1.3,1.3,1.9,3.3,1.9,5.6h-8.5c0.2,1.8,1.2,2.8,2.8,2.8
		c1.1,0,1.8-0.4,2.1-1.1h3.3c-0.6,2.1-2.5,3.7-5.5,3.7C426.1,612.1,423.8,609.4,423.8,605.9z M427.2,604.8h5c-0.1-1.6-1-2.6-2.4-2.6
		C428.2,602.2,427.4,603.2,427.2,604.8z"
        />
        <path
          class="st2"
          d="M437.4,600h3.3v1.8h0.1c0.7-1.4,1.9-2.2,3.5-2.2c1.5,0,2.6,0.8,3.2,2.2l0,0c1-1.6,2.2-2.2,3.7-2.2
		c2.6,0,3.9,1.7,3.9,4.3v7.7h-3.4v-7.2c0-1.3-0.6-2-1.7-2c-1.2,0-2,1-2,2.5v6.7h-3.5v-7.2c0-1.3-0.6-2-1.7-2c-1.2,0-2,1-2,2.5v6.7
		h-3.5V600H437.4z"
        />
        <path
          class="st2"
          d="M456.9,605.9c0-3.5,2.4-6.2,6-6.2c1.7,0,3,0.6,4,1.5c1.3,1.3,1.9,3.3,1.9,5.6h-8.5c0.2,1.8,1.2,2.8,2.8,2.8
		c1.1,0,1.8-0.4,2.1-1.1h3.3c-0.6,2.1-2.5,3.7-5.5,3.7C459.3,612.1,456.9,609.4,456.9,605.9z M460.3,604.8h5c-0.1-1.6-1-2.6-2.4-2.6
		C461.3,602.2,460.5,603.2,460.3,604.8z"
        />
        <path
          class="st2"
          d="M473.9,601.8L473.9,601.8c0.8-1.4,2-2.1,3.7-2.1c2.6,0,4.1,1.7,4.1,4.3v7.7h-3.4v-7c0-1.3-0.7-2.1-2-2.1
		s-2.2,1.1-2.2,2.6v6.6h-3.5V600h3.3V601.8z"
        />
        <path
          class="st2"
          d="M483,600h1.8v-3.7h3.4v3.7h2.3v2.2h-2.3v5.8c0,0.9,0.5,1.1,1.3,1.1c0.2,0,0.6,0,0.8,0h0.2v2.6
		c-0.4,0.1-1.1,0.2-2,0.2c-2.2,0-3.7-0.7-3.7-3.1v-6.6H483V600z"
        />
        <path
          class="st2"
          d="M497.5,600h3.3v1.7h0.1c0.8-1.3,2-2,3.6-2c3.1,0,5,2.6,5,6.2c0,3.8-2,6.2-5.1,6.2c-1.5,0-2.8-0.7-3.5-1.8l0,0
		c0,0.4,0.1,1.3,0.1,2.6v2.8h-3.5V600z M506,606c0-2.1-0.8-3.5-2.5-3.5c-1.8,0-2.6,1.6-2.6,3.5c0,2,0.9,3.4,2.7,3.4
		C505.1,609.4,506,608.1,506,606z"
        />
        <path
          class="st2"
          d="M514.5,602.1L514.5,602.1c0.8-1.6,1.9-2.3,3.3-2.3c0.3,0,0.6,0,0.7,0.1v3h-0.1c-0.2,0-0.5-0.1-0.7-0.1
		c-1.9,0-3.1,1.1-3.1,3.4v5.4h-3.5V600h3.3L514.5,602.1L514.5,602.1z"
        />
        <path
          class="st2"
          d="M519.5,605.9c0-3.5,2.5-6.2,6.2-6.2s6.2,2.7,6.2,6.2s-2.4,6.2-6.2,6.2C521.9,612.1,519.5,609.4,519.5,605.9z
		 M528.3,605.9c0-2.2-1-3.7-2.7-3.7c-1.8,0-2.7,1.5-2.7,3.7s0.9,3.7,2.7,3.7C527.4,609.6,528.3,608.1,528.3,605.9z"
        />
        <path
          class="st2"
          d="M532.1,600h3.6l1.4,4.7c0.5,1.6,0.9,4.1,0.9,4.1h0.1c0,0,0.4-2.5,0.9-4.1l1.5-4.7h3.5l-4.1,11.7h-3.7
		L532.1,600z"
        />
        <path class="st2" d="M545.2,595.4h3.5v3h-3.5V595.4z M545.2,600h3.5v11.7h-3.5V600z" />
        <path
          class="st2"
          d="M550.2,608.1h3.3c0.2,1.1,1,1.7,2.4,1.7c1.2,0,1.9-0.5,1.9-1.3c0-1.1-1.4-1.2-3-1.5c-2.1-0.4-4.2-0.9-4.2-3.7
		c0-2.4,2.2-3.7,4.9-3.7c3.3,0,4.9,1.4,5.2,3.5h-3.2c-0.2-0.8-0.9-1.3-2-1.3s-1.7,0.4-1.7,1.2c0,0.9,1.3,1,2.9,1.3
		c2.1,0.4,4.5,0.9,4.5,3.9c0,2.5-2.2,3.9-5.3,3.9C552.4,612.1,550.4,610.5,550.2,608.1z"
        />
        <path class="st2" d="M562.8,595.4h3.5v3h-3.5V595.4z M562.8,600h3.5v11.7h-3.5V600z" />
        <path
          class="st2"
          d="M568,605.9c0-3.5,2.5-6.2,6.2-6.2s6.2,2.7,6.2,6.2s-2.4,6.2-6.2,6.2C570.5,612.1,568,609.4,568,605.9z
		 M576.9,605.9c0-2.2-1-3.7-2.7-3.7c-1.8,0-2.7,1.5-2.7,3.7s0.9,3.7,2.7,3.7C575.9,609.6,576.9,608.1,576.9,605.9z"
        />
        <path
          class="st2"
          d="M585.4,601.8L585.4,601.8c0.8-1.4,2-2.1,3.7-2.1c2.6,0,4.1,1.7,4.1,4.3v7.7h-3.4v-7c0-1.3-0.7-2.1-2-2.1
		s-2.2,1.1-2.2,2.6v6.6h-3.5V600h3.3L585.4,601.8L585.4,601.8z"
        />
      </g>
      <g>
        <path
          class="st8"
          d="M531,59.2l-45.8-19.1c-1.3-0.5-2.8-0.8-4.3-0.8s-3,0.3-4.3,0.8l-45.8,19.1c-4.3,1.8-7.1,5.9-7.1,10.5
		c0,47.4,27.3,80,52.9,90.7c1.3,0.5,2.8,0.8,4.3,0.8s3-0.3,4.3-0.8c25.5-10.7,52.9-43.3,52.9-90.7C538.1,65.1,535.3,60.8,531,59.2z
		 M480.9,152h-0.3c-25.5-12.7-46.9-42.5-47.7-83.8l47.9-19.9h0.3L529,68.2C528,109.5,506.6,139.3,480.9,152z"
        />
        <path
          class="st0"
          d="M480.8,48.3l-47.9,19.9c1,41.3,22.4,71.1,47.9,83.8h0.3c25.5-12.7,47.1-42.6,47.9-83.8L480.8,48.3L480.8,48.3z
		"
        />
        <g>
          <path
            class="st9"
            d="M458.4,92.6c0-7.2,4.6-12.7,11.9-12.7c2.6,0,4.9,0.7,6.6,1.8c2.1,1.6,3.5,4,3.8,6.8H475c-0.3-2.3-2.1-4-4.9-4
			c-4.1,0-6.3,3.5-6.3,8.1s2.5,8.1,6.3,8.1c3,0,4.8-2,5.1-4.4h5.6c-0.2,2.5-1.3,4.6-3,6.3c-1.8,1.8-4.3,2.8-7.7,2.8
			C463.2,105.3,458.4,99.8,458.4,92.6z"
          />
          <path
            class="st9"
            d="M483.7,80.4h10.9c2.8,0,5.1,0.8,6.4,2.3c1.5,1.5,2.3,3.5,2.3,5.8c0,4.6-3.1,7.7-8.2,7.7h-5.8v8.4h-5.6
			L483.7,80.4L483.7,80.4z M489.5,91.9h4.8c2.3,0,3.6-1.3,3.6-3.5c0-2.1-1.3-3.3-3.6-3.3h-4.8V91.9z"
          />
        </g>
      </g>
      <g>
        <circle class="st8" cx="165.2" cy="159.6" r="23.8" />
        <path class="st9" d="M167.2,171.3h-4v-9.7h-9.7v-4h9.7V148h4v9.7h9.7v4h-9.7V171.3z" />
      </g>
      <path
        class="st0"
        d="M363.3,162.2l-29.2,29.2c-1.4,1.4-3.7,1.4-5.1,0l-3.4-3.4c-1.4-1.4-1.4-3.7,0-5.1l23.2-23.3l-23.2-23.3
	c-1.4-1.4-1.4-3.7,0-5.1l3.4-3.4c1.4-1.4,3.7-1.4,5.1,0l29.2,29.2C364.7,158.5,364.7,160.8,363.3,162.2z"
      />
      <rect x="14.8" y="53.9" class="st10" width="300.7" height="25" />
      <text transform="matrix(1 0 0 1 23.4883 68.0163)">
        <tspan x="0" y="0" class="st6 st11 st12">from</tspan>
        <tspan x="47.7" y="0" class="st6 st13 st12">292,00</tspan>
        <tspan x="114.5" y="0" class="st6 st14 st12"></tspan>
        <tspan x="119.5" y="0" class="st6 st11 st12">to</tspan>
        <tspan x="142.9" y="0" class="st6 st13 st12">584,00 €</tspan>
        <tspan x="228.3" y="0" class="st6 st14 st12"></tspan>
        <tspan x="233.4" y="0" class="st6 st11 st12">mthly</tspan>
      </text>
      <rect x="14.8" y="83.2" class="st10" width="300.7" height="31.5" />
      <text transform="matrix(1 0 0 1 91.5167 94.3691)">
        <tspan x="0" y="0" class="st6 st11 st15">without deduction of</tspan>
        <tspan x="53.9" y="18" class="st6 st11 st15">taxes</tspan>
      </text>
      <rect x="14.8" y="205" class="st10" width="300.7" height="25" />
      <text transform="matrix(1 0 0 1 79.6314 219.8409)">
        <tspan x="0" y="0" class="st6 st11 st16">to</tspan>
        <tspan x="24.6" y="0" class="st6 st13 st16">292,00 €</tspan>
        <tspan x="113.4" y="0" class="st6 st11 st16">mthly</tspan>
      </text>
      <g>
        <rect x="14.8" y="237.3" class="st10" width="300.7" height="41.3" />
        <text transform="matrix(1 0 0 1 91.5167 248.4498)">
          <tspan x="0" y="0" class="st6 st11 st15">without deduction of</tspan>
          <tspan x="-6.3" y="18" class="st6 st11 st15">taxes &amp; social security</tspan>
        </text>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: ['currentLang'],
};
</script>

<style lang="scss">
.de {
  .st0 {
    fill: #6edec8;
  }
  .st1 {
    fill: #f9b60a;
  }
  .st2 {
    fill: #6bbdff;
  }
  .st3 {
    fill: #1895f9;
  }
  .st4 {
    enable-background: new;
  }
  .st5 {
    fill: #2930ce;
  }
  .st6 {
    fill: #b25b06;
  }
  .st7 {
    fill: #039181;
  }
  .st8 {
    fill: #f98504;
  }
  .st9 {
    fill: #37b59d;
  }
  .st10 {
    fill: #ffffff;
  }
  .st11 {
    fill: none;
  }
  .st12 {
    font-family: 'NHaasGroteskTXPro-65Md';
  }
  .st13 {
    font-size: 20px;
  }
  .st14 {
    font-family: 'NHaasGroteskTXPro-55Rg';
  }
  .st15 {
    font-family: 'NHaasGroteskTXPro-75Bd';
  }
  .st16 {
    font-size: 15px;
  }
}
.en {
  .st0 {
    fill: #6edec8;
  }
  .st1 {
    fill: #f9b60a;
  }
  .st2 {
    fill: #6bbdff;
  }
  .st3 {
    fill: #1895f9;
  }
  .st4 {
    fill: #2930ce;
  }
  .st5 {
    fill: #b25b06;
  }
  .st6 {
    fill: #039181;
  }
  .st7 {
    fill: #f98504;
  }
  .st8 {
    fill: #37b59d;
  }
  .st9 {
    fill: #ffffff;
  }
  .st10 {
    fill: none;
  }
  .st11 {
    font-family: 'NHaasGroteskTXPro-65Md';
  }
  .st12 {
    font-size: 19px;
  }
  .st13 {
    font-family: 'NHaasGroteskTXPro-75Bd';
  }
  .st14 {
    font-family: 'NHaasGroteskTXPro-55Rg';
  }
  .st15 {
    font-size: 15px;
  }
  .st16 {
    font-size: 20px;
  }
}
</style>
