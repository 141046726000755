var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-sm-10 py5"},[_c('div',{staticClass:"row"},[_c('v-col',{staticClass:"mx-auto col-sm-12 col-md-10 col-lg-10 col-12"},[_c('v-card',{staticClass:"v-card--no-border",attrs:{"color":"transparent"}},[_c('h1',{staticClass:"font-weight-bold mb-5 mt-7 text-center"},[_vm._v(_vm._s(_vm.$t('bavInfoHeading1')))]),_c('p',{staticClass:"mb-12 text-center"},[_vm._v(" "+_vm._s(_vm.$t('introSubFAQ'))+" ")])]),_c('v-card',{staticClass:"v-card--no-border d-flex",attrs:{"color":"transparent"}},[_c('v-avatar',{staticClass:"mb-5 primary",attrs:{"size":"45"}},[_c('v-icon',{attrs:{"color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiHelp)+" ")])],1),_c('v-card-text',{staticClass:"primary--text"},[_c('h3',[_vm._v(_vm._s(_vm.$t('subheadlineFAQ')))]),_c('p',[_vm._v(_vm._s(_vm.$t('subHeadingFAQ')))])])],1),_c('v-expansion-panels',{attrs:{"accordion":"","focusable":""}},_vm._l((17),function(item){return _c('v-expansion-panel',{key:item},[_c('v-expansion-panel-header',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t(("faqQuestion" + item)))+" ")]),_c('v-expansion-panel-content',[(item !== 4)?_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.sanitizeHtml(
                  _vm.$t(("faqAnswer" + item), {
                    imgBenefits: require('@/assets/static/images/FAQ_DE_Benefits_and_returns.svg'),
                    imgStatutory: require('@/assets/static/images/FAQ_DE_Statutory_Pension.svg'),
                    imgMorePensionEN: require('@//assets/static/images/Degura_Graphic_More-Pension_EN.svg'),
                    imgMorePensionDE: require('@/assets/static/images/Degura_Graphic_More-Pension.svg'),
                    imgRecomContrEN: require('@/assets/static/images/Degura_Graphic_Recommended-Contribution_EN.svg'),
                    imgRecomContrDE: require('@/assets/static/images/Degura_Graphic_Recommended-Contribution.svg'),
                    imgValueDevEN: require('@/assets/static/images/Degura_Graphic_Value-Development_EN.svg'),
                    imgValueDevDE: require('@/assets/static/images/Degura_Graphic_Value-Development.svg'),
                    imgDeathDE: require('@/assets/static/images/Degura_Graphic_Death.svg'),
                    imgDeathEN: require('@/assets/static/images/Degura_Graphic_Death_EN.svg'),
                    imgValueMaxContributonDE: require('@/assets/static/images/Degura_Graphic_Max-Contribution_2023-DE.svg'),
                  }),
                  {
                    allowedTags: _vm.sanitizeHtml.defaults.allowedTags.concat(['img']),
                    allowedAttributes: Object.assign({}, _vm.sanitizeHtml.defaults.allowedAttributes,
                      {img: ['src', 'alt', 'title', 'loading', 'class']}),
                  }
                )
              )}}):_c('p',[_vm._v(" "+_vm._s(_vm.$t('faqAnswer4'))+" ")])])],1)}),1)],1)],1),_c('v-card',{staticClass:"v-card--no-border mb-3",attrs:{"color":"transparent"}},[_c('div',{staticStyle:{"margin":"5rem 0 0 0","text-align":"center"}},[_c('h2',{staticClass:"font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.$t('headlineContactFAQ')))]),_c('p',[_vm._v(_vm._s(_vm.$t('subheadlineContactFAQ')))])]),_c('div',{staticClass:"v-card--no-border d-flex flex-row align-center justify-center"},[_c('support-bubble-static')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }